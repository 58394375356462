import { useEffect, useState } from 'react';

export function useDebounce<T>(value: T, delay = 500): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  let timer: ReturnType<typeof setTimeout> | null = null;

  const clear = () => {
    if (!timer) return;
    clearTimeout(timer);
    timer = null;
  };

  useEffect(() => {
    clear();

    timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clear();
    };
  }, [value, delay]);

  return debouncedValue;
}
