import { RootStackNavigationProp } from '../../navigation/RootNavigation';
import { useResetPasswordState } from './reset-password-store';
import ResetPasswordService from '../../api/ResetPasswordService';
import { AxiosError } from 'axios';
import { ResetPasswordError } from '../../store/types';
import { getText } from 'assets/localization/localization';

export const resetPassword = async (
  values: ResetPasswordForm,
  navigation: RootStackNavigationProp,
): Promise<void> => {
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  try {
    await ResetPasswordService.resetPassword({ email: values.email });

    useResetPasswordState.setState({ status: 'success' });
    // useUserState.setState({ data: userResetPassword as any }); // TODO: is this needed?
    navigation.navigate('reset-link-sent');
  } catch (e) {
    // TODO: should we redirect to the same page on 404??
    if ((e as AxiosError).response?.status === 404) {
      navigation.navigate('reset-link-sent');
    }
    useResetPasswordState.setState({
      error: { message: getText('unable-to-send-reset-link') },
      status: 'error',
    });
  }
};

export const confirmPassword = async (
  values: ResetPasswordModel,
  navigation: RootStackNavigationProp,
): Promise<void> => {
  useResetPasswordState.setState({ error: undefined, status: 'loading' });

  try {
    await ResetPasswordService.confirmPassword({
      email: values.email,
      password: values.password,
      reset_password_link_id: values.resetPasswordLinkId,
      confirmation_code: values.confirmationCode,
    });
    useResetPasswordState.setState({ status: 'success' });
    navigation.navigate('login');
  } catch (e) {
    useResetPasswordState.setState({
      error: { message: e as ResetPasswordError },
      status: 'error',
    });
  }
};

export interface ResetPasswordForm {
  email: string;
}

export interface ConfirmPasswordForm {
  password: string;
  passwordConfirm: string;
}

export interface ResetPasswordModel
  extends ResetPasswordForm,
    Omit<ConfirmPasswordForm, 'passwordConfirm'> {
  resetPasswordLinkId: string;
  confirmationCode: string;
}
