import { TranslationMapping } from "../localization";

const mapping: TranslationMapping = {
  "signup": "S'inscrire",
  "login": "Connexion",
  "username": "Nom d'utilisateur",
  "password": "Mot de passe",
  "dashboard": "Tableau de bord",
  "medications": "Médicaments",
  "messages": "Messages",
  "profile": "Profil",
  "get-care": "Obtenez des soins",
  "lets-get-to-know-you": "Apprenons à vous connaître",
  "patient-select": "Sélectionnez le patient avant de commencer.",
  "create-first-form": "Créez votre premier formulaire",
  "pharmacy-additional-closures-inactive": "inactive",
  "credentials": "Informations d'identification",
  "lumistry-api-key": `La clé API que vous fournissez donnera à la lumistry accès à vos données Jotform, y compris les formulaires et les soumissions.Vos données seront maintenues en sécurité et ne seront utilisées que dans le but d'importer les formulaires que vous spécifiez dans la plate-forme de lumistry.`,
  "lets-get-to-know-you-details": "Remplissez votre nouveau formulaire de patient afin que nous ayons toutes vos informations dans le dossier. Cela garantit que nous pouvons remplir votre ordonnance correctement.",
  "welcome": "Bienvenu",
  "logout": "Se déconnecter",
  "get-mobile-app": "Obtenez notre application mobile!",
  "front": "De face",
  "back": "Retour",
  "sent": "Envoyée",
  "all-tasks": "Toutes les tâches",
  "every-n-weeks": "Toutes les {{number}} semaines",
  "every-n-months": "Chaque {{number}} mois",
  "see-order-details": "Voir les détails de la commande",
  "rx-order-progress": "Ordre #{{orderId}} en cours",
  "prescriptions-filled": "{{filled}} des {{total}} ordonnances sont remplies",
  "manage-subscription": "Gérez vos prescriptions de votre famille et votre famille, communiquez avec votre pharmacie, accédez au matériel éducatif, planifiez les rendez-vous, et plus!",
  "coming-soon": "Prévu pour MVP, pas encore disponible",
  "after-adding-patient-message": "Après avoir ajouté un nouveau patient, veuillez revenir au message d'origine et cliquer à nouveau sur le lien.",
  "section-on-the-roadmap": "{{section}} sur la feuille de route, pas encore disponible",
  "go-to-form": "Aller à la forme",
  "bottle-cap-preference": "Préférences de bouchon de bouteille",
  "allergies-conditions-caregivers": "Allergies, conditions et soignants",
  "insurance-id-card": "Assurance et pièce d'identité Carte",
  "patient-forms": "Formulaires des patients",
  "communication-preferences": "Préférences de communication",
  "sign-out": "Se déconnecter",
  "appointments": "Rendez-vous",
  "add-medications": "Ajouter des médicaments",
  "insurance-and-id-cards": "Assurances et cartes d'identité",
  "primary": "Primaire",
  "secondary": "Secondaire",
  "id-card": "Carte d'identité",
  "add-meds-text": "Vous pouvez facilement commander des renouvellements d'ordonnance et gérer les médicaments en les ajoutant maintenant, ou sauter cette étape pour le faire plus tard",
  "prescriptions-at-file": "Les ordonnances sont archivées à la pharmacie Cameron",
  "request-to-transfer": "Demande de transfert d'ordonnances d'une autre pharmacie",
  "skip-this-step": "Passer cette étape",
  "do-this-medications-tab": "Vous pouvez le faire dans l'onglet Médicaments",
  "account": "Compte",
  "your-account": "Votre Compte",
  "upcoming-refill": "Recharge à venir",
  "make-primary": "Rendre primaire",
  "updated": "Mise à jour le {{date}}",
  "send-message": "Envoyer le message",
  "closed": "Fermée",
  "another-account": "Un autre compte",
  "back-to-login": "Retour au login",
  "otc-medications": "Médicaments en vente libre",
  "anonymous-refill": "Recharge anonyme",
  "next": "Suivant",
  "revoke": "Révoquer",
  "about-this-app": "À propos de cette application",
  "copyright-lumistry": "Droits d'auteur Lumistry",
  "all-rights-reserved": "Tous les droits sont réservés",
  "version": "Version",
  "scan-barcode": "Scanner le code barre",
  "add-card": "Ajouter une carte...",
  "primary-secondary-photo-id": "Primaire, secondaire, photo d'identité",
  "login-attempts-exceeded": "Tentatives de connexion dépassées",
  "max-attempts-exceeded": "Nombre maximal de tentatives dépassé",
  "account-locked-reason": "Votre compte a été verrouillé car vous avez atteint le nombre maximal de tentatives de connexion invalides autorisé.",
  "security-reason": "Pour des raisons de sécurité, veuillez choisir une option ci-dessous pour modifier votre mot de passe et récupérer votre compte.",
  "send-me-email": "Envoie-moi un email",
  "send-me-sms": "Envoyez-moi un SMS",
  "scanned": "Scanné",
  "save": "Enregistrer",
  "meds": "Médicaments",
  "orders": "Ordres",
  "reminders": "Rappels",
  "coming-up": "À venir :",
  "view": "Voir",
  "update-personal-info": "Mettre à jour les informations personnelles",
  "name-phone-email": "Nom, téléphone, email, etc.",
  "legal-guardian-confirmation-for-person": "Vous convenez que vous êtes un parent ou un tuteur légal pour la personne que vous ajoutez",
  "legal-guardian-agreement": "Oui, je suis un parent ou un tuteur légal pour {{firstName}} {{lastName}}.",
  "confirmation": "Confirmation",
  "add-minor-or-pet-patient": "Ajouter un patient mineur ou animal de compagnie",
  "your-allergies": "Vos allergies",
  "revoke-access-for-authorized-caregiver": "La révocation de l'accès signifie que {{firstName}} {{lastName}} ne fait plus partie de vos soignants autorisés.",
  "forgot-password": "Mot de passe oublié?",
  "camera-access-denied": "Impossible d'accéder à la caméra, veuillez accéder aux paramètres de votre application et accorder l'accès à la caméra.",
  "username-is-required": "Nom d'utilisateur est requis",
  "password-is-required": "Mot de passe est requis",
  "view-full-profile": "Voir le profil complet",
  "first-name-is-not-valid": "Le prénom n'est pas valide",
  "last-name-is-not-valid": "Le nom de famille n'est pas valide",
  "insurance-card": "Carte d'assurance",
  "barcode-scanner": "Scanner le code barre",
  "english": "Anglais",
  "spanish": "Espagnol",
  "french": "Français",
  "yes": "Oui",
  "no": "Non",
  "continue": "Continuer",
  "first-name": "Prénom *",
  "first-name-is-required": "Prénom est requis",
  "last-name": "Nom de famille *",
  "last-name-is-required": "Nom de famille est requis",
  "email-short": "E-mail",
  "monday": "Lundi",
  "tuesday": "Mardi",
  "wednesday": "Mercredi",
  "thursday": "Jeudi",
  "friday": "Vendredi",
  "saturday": "Samedi",
  "sunday": "Dimanche",
  "email": "Adresse électronique",
  "email-is-required": "Email est requis",
  "email-continue": "Continuer avec l'e-mail",
  "mobile-number": "Numéro de téléphone mobile *",
  "greeting": "Bonjour",
  "mobile-number-is-required": "Numéro de téléphone mobile est requis",
  "birth-date": "Date de naissance *",
  "birth-date-is-required": "Date de naissance est requise",
  "lets-get-started": "Commençons par créer un compte.",
  "front-of-card": "Face de la carte",
  "add-front-of-card": "Face de la carte",
  "caregiver-approval-agreement": "Accord d'approbation de l'aidant",
  "no-known-allergies": "Aucune allergie connue",
  "known-allergies": "Allergies connues",
  "allergies-required": "Veuillez saisir les allergies ou sélectionner \"Aucune allergie connue\"",
  "more-information": "Plus d'information",
  "monthly-prescriptions": "Souhaitez-vous que vos ordonnances soient prêtes ensemble à la même heure chaque mois ?",
  "as-an-authorized-caregiver": "En tant que soignant autorisé, vous pouvez :",
  "manage-prescriptions-digitally": "Gérez numériquement les ordonnances de {{firstName}}",
  "order-refills": "Commander des recharges pour {{firstName}}",
  "order-refills-prescriptions": "Commander des recharges pour ordonnances",
  "delivery": "Livraison",
  "mail": "Poster",
  "curbside": "Trottoir",
  "relation-to-primary-user": "Relation avec l'utilisateur principal",
  "would-like-easy-open-caps": "Souhaitez-vous des bouchons de bouteilles faciles à ouvrir ?",
  "prefers-easy-open-caps": "Oui, je préfère les bouchons à ouverture facile",
  "prefers-safety-caps": "Non, je préfère les bouchons de sécurité",
  "pick-up-prescriptions-for": "Procurez-vous des ordonnances pour {{firstName}}",
  "discuss-care-with": "Discutez des soins de {{firstName}} avec un membre de l'équipe de {{pharmacyName}}",
  "manage-health-and-clinic": "Gérer les rendez-vous de santé et de service clinique de {{firstName}}",
  "access-validity-puc": "Cet accès est valide jusqu'à ce que Chris ait 18 ans ou que vous supprimiez Chris de votre liste de personnes dont vous avez la charge.",
  "update-puc-list": "Vous pouvez mettre à jour votre liste en accédant à Personnes dont je m'occupe dans les paramètres du compte",
  "patient-record-created": "Dossier patient créé pour {{firstName}} {{lastName}}",
  "back-of-card": "Face arrière de la carte",
  "add-back-of-card": "Face arrière de la carte",
  "cancel": "Annuler",
  "cancellations": "Annulations",
  "retake": "Retourner",
  "use-photo": "Utiliser la photo",
  "password-must-include": "Le mot de passe doit inclure",
  "at-least-8-characters": "au moins 8 caractères",
  "at-least-1-number": "au moins 1 chiffre",
  "at-least-1-capital-letter": "au moins 1 lettre majuscule",
  "at-least-1-lowercase-letter": "au moins 1 lettre minuscule",
  "at-least-1-special-character": "au moins 1 caractère spécial",
  "privacy-policy-error": "Veuillez lire la politique de confidentialité avant d'accepter",
  "privacy-policy-agree": "J'accepte les termes et conditions ci-dessus",
  "privacy-policy-email-signup": "Inscrivez-vous aux courriels de ma pharmacie",
  "pharmacy-privacy-policy": "Politique de confidentialité de la Pharmacie",
  "terms-and-conditions": "Fournisseur de Logiciels Termes & Conditions",
  "terms-of-service": "Termes et conditions",
  "add-patient": "Ajouter un patient",
  "manage-prescriptions": "Gérez vos ordonnances numériquement",
  "pick-up-prescriptions": "Récupérez vos ordonnances",
  "discuss-care": "Discutez de vos soins avec un membre de l'équipe {{pharmacyName}}",
  "manage-health": "Gérez vos rendez-vous de santé et de services cliniques",
  "request-status": "En attente : soumis le {{submittedDate}}",
  "authorized-caregivers": "Aaidants autorisés",
  "terms-of-service-error": "Veuillez lire les conditions d'utilisation avant d'accepter",
  "terms-of-service-agree": "J'accepte les termes et conditions ci-dessus",
  "terms-of-service-email-signup": "Inscrivez-vous aux e-mails",
  "puc-permission-access-to-records": "Je confirme que {{firstName}} {{lastName}} est autorisé à accéder à mes dossiers et à gérer ma santé numériquement et que je peux révoquer l'accès à tout moment.",
  "if-you-authorize-person": "Si vous autorisez cette demande, cette personne peut :",
  "access-validity": "Cet accès est valide jusqu'à ce que vous le révoquiez.",
  "revoke-access": "Revocar acceso",
  "authorized-on": "Autorisé le {{authorizedDate}}",
  "revoke-access-in-settings": "Vous pouvez révoquer l'accès en accédant à vos informations médicales dans les paramètres du compte",
  "review-request": "Demande de révision...",
  "authorize": "Autoriser",
  "decline": "Déclin",
  "and": "et",
  "submit": "Soumettre",
  "2-of-2-agreements": "2 accords sur 2",
  "medical-info-step-3": "Étape 3 sur 8 / Ajoutez vos informations médicales",
  "password-entry-does-not-meet-criteria": "La saisie du mot de passe ne répond pas aux critères",
  "password-entries-do-not-match": "Les entrées de mot de passe ne correspondent pas",
  "create-your-account": "Créez votre compte",
  "please-enter-your-new-password": "Veuillez entrer votre nouveau mot de passe",
  "email-is-not-valid": "Email n'est pas valide",
  "phone-is-not-valid": "Le téléphone n'est pas valide",
  "create-account": "Créer un compte",
  "confirm-password": "Confirmer le mot de passe",
  "patient-info-step": "Étape x sur y / Confirmez vos informations",
  "communications-language": "Langue de communication",
  "prescriptions-communications": "Communications sur les ordonnances: comment souhaitez-vous être averti lorsque vos ordonnances sont prêtes?",
  "automated-voice-call": "Appel vocal automatisé",
  "text-message": "Message texte",
  "app-notifications": "Notifications d'application",
  "all-other-pharmacy-communications": "Toutes les autres communications de la pharmacie",
  "same-time-preferences": "Souhaitez-vous que vos ordonnances soient prêtes ensemble à la même heure chaque mois?",
  "additional-information": "Informations Complémentaires",
  "visually-impaired": "Malvoyant",
  "hearing-impaired": "Malentendant",
  "easy-open-bottle-caps": "Bouchons de bouteilles faciles à ouvrir",
  "personal-info-step-2": "Étape 2 sur 7 / Ajoutez vos informations personnelles",
  "country": "Pays",
  "country-is-required": "Pays est requis",
  "state": "État",
  "state-is-required": "État est requis",
  "city": "Ville",
  "city-is-required": "Ville est requise",
  "street-address": "Adresse",
  "street-address-is-required": "Adresse est requise",
  "street-address-line-2": "Adresse ligne 2",
  "zip-code": "Code postal",
  "zip-code-is-required": "Code postal est requis",
  "gender": "Le genre (tel qu'il apparaît sur votre assurance)*",
  "gender-is-required": "Le sexe est requis",
  "male": "Mâle",
  "female": "Femelle",
  "verification-code-resent": "Code de vérification renvoyé",
  "please-enter-code": "Veuillez saisir le code que nous avons envoyé par e-mail",
  "didnt-receive-email": "Vous n'avez pas reçu l'e-mail?",
  "please-check-spam-folder": "Veuillez vérifier votre dossier spam ou renvoyer le code",
  "didnt-receive-text-message": "Vous n'avez pas reçu le SMS ?",
  "phone-number-is-sms-capable": "Veuillez vous assurer que le numéro de téléphone que vous avez saisi est compatible avec les SMS ou renvoyez le code.",
  "standard-text-messages-apply": "Des tarifs de messagerie texte standard peuvent s'appliquer selon votre fournisseur de services.",
  "resend-code": "Renvoyer le code",
  "reset-password": "Réinitialiser le mot de passe",
  "reset-link-sent": "Si l'e-mail est associé à un compte actif, vous devriez recevoir un lien de réinitialisation.",
  "please-enter-email": "Veuillez saisir l'adresse e-mail associée à votre compte",
  "how-would-you-like-to-receive-reset-link": "Comment souhaitez-vous recevoir le lien de réinitialisation?",
  "using-the-phone-number-on-file": "En utilisant le numéro de téléphone enregistré",
  "send-reset-link": "Envoyer le lien de réinitialisation",
  "create-new-password": "Créer un nouveau mot de passe",
  "confirm-new-password": "Confirmer le mot de passe *",
  "new-password": "Nouveau mot de passe *",
  "for-security-reasons": "Pour des raisons de sécurité, votre mot de passe expire tous les 90 jours, veuillez mettre à jour votre mot de passe",
  "update-password": "Mettre à jour le mot de passe",
  "password-expiring-soon": "Le mot de passe expire bientôt",
  "password-expire-in-7-days": "Votre mot de passe expirera dans 7 jours",
  "update-password-now": "Voulez-vous mettre à jour votre mot de passe maintenant ?",
  "confirm-update-password": "Confirmez le nouveau mot de passe *",
  "go-to-login": "Retour au login",
  "return-to-login": "Retour au login",
  "apple-signup": "S'inscrire auprès d'Apple",
  "apple-continue": "Continuer avec Apple",
  "apple-login": "Connexion Apple",
  "change-password": "Changer le mot de passe",
  "login-with-email": "Connexion avec l'email",
  "login-enter-email": "Insérer email",
  "passwords-must-match": "Les mots de passe ne correspondent pas",
  "dismiss": "Rejeter",
  "load-more": "Charger plus",
  "loading": "Chargement",
  "open-map": "Ouvrir la carte",
  "input-field": "Champ d'entrée",
  "text-field": "Champ de texte",
  "show-hide-password": "Afficher/masquer le mot de passe",
  "search": "Rechercher",
  "select": "Sélectionner",
  "use": "Utiliser",
  "no-options": "Aucune option",
  "email-or-password-incorrect": "E-mail ou mot de passe incorrect. Veuillez réessayer.",
  "unable-to-complete-registration": "Impossible de terminer l'inscription. Veuillez réessayer.",
  "unable-to-update-account": "Impossible de mettre à jour le compte. Veuillez réessayer.",
  "unable-to-complete-verification": "Impossible de terminer la vérification. Veuillez réessayer.",
  "unable-to-resend-verification-code": "Impossible de renvoyer le code de vérification. Veuillez réessayer.",
  "error-submitting": "Erreur lors de l'envoi. Veuillez réessayer.",
  "unable-to-send-reset-link": "Impossible d'envoyer le lien de réinitialisation. Veuillez réessayer.",
  "unable-to-reset-password": "Impossible de réinitialiser le mot de passe. Veuillez réessayer.",
  "store-selector-change": "Changement",
  "store-selector-search-address": "Code postal",
  "store-selector-change-store": "Changement butique",
  "store-selector-your-store": "Votre magasin",
  "store-selector-nearby-store": "Magasins à proximité",
  "store-selector-close": "Fermer",
  "store-selector-select": "Choisir",
  "store-selector-no-stores-error": "Impossible de trouver l'emplacement de la pharmacie",
  "feedback": "Avis",
  "feedback-give-feedback": "Donnez votre avis",
  "feedback-give-your-feedback": "Donnez votre avis",
  "feedback-please-enter-feedback": "Veuillez entrer votre avis",
  "feedback-how-to-improve-app": "How can we improve our app?",
  "feedback-give-feedback-about-pharmacy": "Give us feedback about our pharmacy",
  "feedback-sent": "Votre avis a été envoyé!",
  "feedback-thank-you": "Merci pour votre avis!",
  "feedback-what-type-of-feedback": "Quel type de commentaires laissez-vous aujourd'hui?",
  "feedback-target-is-required": "La cible de rétroaction est requise.",
  "feedback-feedback": "Entrez des informations supplémentaires",
  "feedback-feedback-is-required": "Des informations de commentaires sont requises.",
  "feedback-allowContact": "J'accepte que vous me contactiez pour plus de précisions.",
  "feedback-thanks-message": "Merci pour vos commentaires!",
  "feedback-reach-you-message": "Nous vous contacterons directement si nous avons des questions.",
  "feedback-back-to-profile": "Retour au profil",
  "feedback-error-occurred": "Une erreur est survenue.",
  "feedback-contact-info": "Informations de contact",
  "feedback-edit": "Éditer",
  "feedback-email-address": "Adresse e-mail",
  "feedback-phone-number": "Numéro de téléphone",
  "feedback-app": "App",
  "feedback-location": "Boutique",
  "phone-number-footer": "téléphone: {{phone}}",
  "fax": "fax: {{fax}}",
  "digital-pharmacist": "Digital Pharmacist",
  "pharmacy-website": "Site Web et application mobile de la pharmacie fournis par",
  "refill-card-medication": "Médicament",
  "refill-card-due-date": "Recharge due",
  "prescription-find-server-error": "Erreur interne du serveur lors de la recherche de vos médicaments",
  "prescription-card-day-supply": "approvisionnement journalier",
  "prescription-card-filled-on": "Rempli le",
  "prescription-card-refills": "Recharges restantes",
  "prescription-card-dob": "DOB",
  "medications-fill-your-prescription": "Remplissez votre ordonnance",
  "medications-ready-to-be-filled": "Prêt à être rempli",
  "medications-other-upcoming-refills": "Autres recharges à venir",
  "insurance-card-upload-error": "Impossible de télécharger la carte. Veuillez réessayer.",
  "camera-mask-label": "Centrez le recto de votre carte dans votre cadre",
  "primary-insurance-add-your-information": "Ajouter votre carte d'assurance principale",
  "secondary-insurance-add-your-information": "Ajouter votre carte d'assurance secondaire",
  "photoId": "Ajoutez votre carte d'identité avec photo",
  "photo-take-card-picture": "Prenez une photo ou téléchargez une image de votre carte d'identité avec photo",
  "insurance-take-card-picture": "Prenez une photo ou téléchargez une image des deux côtés de votre carte",
  "take-photo": "Prendre une photo",
  "choose-photo": "Choisir une photo",
  "insurance-bring-to-store": "je vais l'amener au magasin",
  "google-signup": "S'inscrire avec Google",
  "google-continue": "Continuer avec Google",
  "google-login": "Connexion Google",
  "google-login-failed": "Échec de la connexion Google",
  "date-is-not-valid": "La date n'est pas valide",
  "patient-stores": "Trouvez votre magasin",
  "verify-your-email": "Vérifiez votre adresse e-mail",
  "verify-your-phone": "Vérifiez votre numéro de téléphone",
  "please-enter-your-information": "Veuillez entrer vos informations",
  "email-address": "Adresse e-mail",
  "email-is-invalid": "L'e-mail est invalide",
  "passwords-do-not-match": "Les entrées de mot de passe ne correspondent pas",
  "password-does-not-meet-criteria": "La saisie du mot de passe ne répond pas aux critères",
  "follow-reset-password-instructions": "Veuillez suivre les instructions pour réinitialiser votre mot de passe.",
  "pharmacy-reset-link-sent": "Un courriel a été envoyé à ",
  "need-help": "Besoin d'aide?",
  "forms": "Formulaires",
  "tasks": "Tâches",
  "patients": "Les patients",
  "refill-submissions": "Recharge",
  "refill-submissions-header": "Soumissions de recharge",
  "services": "Prestations de service",
  "same-mailing-address": "Pareil que l'adresse postale",
  "user": "Utilisateur",
  "users": "Utilisateurs",
  "new-user": "Nouvel utilisateur",
  "user-details": "Détails de l'utilisateur",
  "title": "Titre",
  "role": "Rôle",
  "days": "Jours",
  "show-grid": "Afficher sur la grille",
  "task-appear-grid": "La tâche apparaîtra sur la grille et la liste des vues.",
  "last-active": "Dernière activité",
  "new-task": "Nouvelle tâche",
  "summary": "Résumé",
  "unknown": "Inconnue",
  "year": "année",
  "month": "mois",
  "day": "jour",
  "week": "semaine",
  "apply-to-all": "S'applique à tous",
  "all-statuses": "Tous les statuts",
  "all-priorities": "Toutes les priorités",
  "all-visibilities": "Tous les visibilités",
  "all-types": "Tous les types",
  "person": "Personne",
  "subtitle": "Sous-titre",
  "edit-title": "Modifier le titre ",
  "edit-subtitle": "Modifier le sous-titre",
  "edit-checkbox-label": "Modifier l'étiquette de la case à cocher",
  "maximum-input-value": "Valeur d'entrée maximale",
  "set-to-default": "Définir par défaut",
  "availability-name": "Nom de disponibilité",
  "name-is-required": "Le nom est requis",
  "display-name": "Afficher un nom",
  "length": "Longueur",
  "account-holder": "Titulaire de compte",
  "restriction": "restrictions",
  "monday-abbreviation": "Lun",
  "tuesday-abbreviation": "Mar",
  "wednesday-abbreviation": "Mir",
  "thursday-abbreviation": "Jeu",
  "friday-abbreviation": "Ven",
  "saturday-abbreviation": "Sam",
  "sunday-abbreviation": "Dim",
  "frequency": "Fréquence",
  "end-date": "Date de fin",
  "sort-by-due-date": "Trier par date d'échéance",
  "sort-by-assignee": "Trier par cessionnaire",
  "sort-by-task-summary": "Trier par résumé des tâches",
  "ascending": "Ascendante",
  "descending": "Descendante",
  "arabic": "Arabe",
  "chinese": "Chinoise",
  "vietnamese": "Vietnamienne",
  "korean": "Coréenne",
  "german": "Allemande",
  "russian": "Russe",
  "fullname": "Nom et prénom",
  "signature": "signature",
  "type-your-question": "Taper une question",
  "select-single-option": "Veuillez sélectionner une seule option",
  "select-multiple-options": "Veuillez sélectionner plusieurs options",
  "please-upload-image": "Veuillez télécharger une image",
  "number": "Nombre",
  "medical-conditions-without-colon": "Les conditions médicales",
  "please-select": "Veuillez sélectionner",
  "every-week": "Toutes les semaines",
  "weeks": "semaines",
  "every": "Toutes les",
  "months": "mois",
  "last-day": "Dernier jour",
  "enter-valid-phone-number": "s'il vous plaît entrer un numéro de téléphone valide.",
  "custom-domain": "Domaine personnalisé (facultatif)",
  "service-required": "Le service est requis",
  "required-field": "champs requis",
  "edit-form-title": "Modifier le titre du formulaire",
  "date-overrides": "Date Overrides",
  "description": "Description",
  "summary-is-required": "Un résumé est requis",
  "recurrence": "Récurrence",
  "one-time": "Une fois",
  "weekly": "Hebdomadaire",
  "monthly": "Mensuelle",
  "start-date": "Date de début",
  "high": "Haut",
  "low": "Faible",
  "medium": "Moyen",
  "assigned-to": "Assigné à",
  "nobody": "Personne",
  "created-by": "Créé par",
  "task-details": "Détails de la tâche",
  "created": "Créé",
  "updated-without-date": "Mis à jour",
  "no-task": "Aucune tâche à montrer",
  "disable-filters": "Veuillez désactiver certains filtres ou détendre votre requête de recherche.",
  "tasks-grid": "Grille de tâches",
  "search-submissions": "Recherchez les soumissions",
  "create-refill": "Créer une recharge",
  "appointment-filters": "Filtres de rendez-vous",
  "import-form": "Saisir",
  "make-default": "Faire défaut",
  "default-availability": "Disponibilité par défaut",
  "edit-date-override": "Modifier la date de remplacement",
  "no-services-booking": "Pas de services",
  "task-types": "Types de tâches",
  "characters": "personnages",
  "task-type-description-required": "Le type de tâche Description est requise",
  "task-type-description-exceeded": "La description ne doit pas dépasser 130 caractères",
  "delete-task-type-confirm": "En confirmant que vous supprimerez le type de tâche sélectionné.",
  "user-roles": "Rôles des utilisateurs",
  "task-filters": "Filtres",
  "error": "Erreur",
  "options": "options",
  "add-new-option": "Ajouter une nouvelle option",
  "edit-columns": "Modifier les colonnes",
  "add-new-column": "Ajouter une nouvelle colonne",
  "import": "Import",
  "grid": "Grille",
  "browse": "Parcourir",
  "task-type-required": "Le titre de type de tâche est requis",
  "task-type-title-exceeded": "Le titre ne doit pas dépasser 30 caractères",
  "form-imported-text": "Ce formulaire a été importé et il dispose d'une fonctionnalité qui n'est pas actuellement disponible.Vous pouvez toujours prévisualiser le formulaire, mais vous ne pouvez pas y apporter de modifications.",
  "form-not-imported-text": "Ce formulaire contient une fonctionnalité qui n'est pas actuellement prise en charge.Vous pouvez toujours prévisualiser le formulaire, mais vous ne pouvez pas y apporter de modifications.",
  "no-availability": "Pas de disponibilité",
  "new-availability": "Nouvelle disponibilité",
  "submission": "Soumission",
  "no-submission-found": "Aucune soumission trouvée",
  "no-form-templates": "Aucun modèle de formulaire disponible.",
  "lose-data-creating-form": "En confirmant que vous perdrez les progrès actuels sur la création d'un nouveau formulaire à partir d'un modèle.",
  "completed": "Complété",
  "due-date": "Date d'échéance",
  "are-you-sure-to-delete-task": "Êtes-vous sûr de vouloir supprimer la tâche",
  "are-you-sure-to-resolve-task": "Êtes-vous sûr de vouloir résoudre la tâche",
  "create-first-task": "Créez votre première tâche",
  "visibility-personal-policy": "Il sera consulté par vous et le cessionnaire (si elle est affectée)",
  "visibility-team-policy": "Il sera vu par tout le monde",
  "start-date-required": "La date de début est requise",
  "registered": "Inscrit",
  "activity": "Activité",
  "show": "Montrer",
  "view-activity-logs": "Afficher les journaux d'activité",
  "resend-invite": "Renvoyer l'invitation",
  "delete": "Supprimer",
  "delete-confirmation": "Êtes-vous sûr de vouloir supprimer cet?",
  "cannot-undo-action": "Vous ne pouvez pas annuler cette action.",
  "set-your-preferences": "Marcher {{currentStep}} de {{totalSteps}} / Définissez vos préférences",
  "find-your-store": "Trouvez votre magasin",
  "confirm-your-store": "Marcher {{currentStep}} de {{totalSteps}} / Confirmez votre magasin",
  "done": "Fait",
  "do-this-later": "Faire ça plus tard",
  "entered-prescription-manually": "Entrée manuellement",
  "your-prescriptions": "Vos ordonnances",
  "add": "Ajouter",
  "drug-name": "Nom du médicament",
  "drug-name-is-required": "Le nom du médicament est requis",
  "add-your-prescriptions": "Ajouter vos prescriptions",
  "step": "Etape",
  "rx-number-is-required": "Le numéro Rx est requis",
  "rx-number-is-duplicated": "Le numéro Rx est déjà présent",
  "change": "Modifier",
  "location": "Emplacement",
  "locations": "Emplacements",
  "patient-dob": "DDN du patient (jj/mm/aaaa) *",
  "patient-dob-is-required": "La date de naissance est obligatoire",
  "patient-last-name-is-required": "Le nom de famille du patient est requis",
  "patient-last-name": "Nom du patient *",
  "we-found-your-medications-alert-title": "Nous avons trouvé vos médicaments",
  "unable-to-locate-your-prescription-alert-title": "Impossible de localiser votre liste de prescriptions",
  "unable-to-locate-your-prescription-alert-description": "Veuillez confirmer que les informations ci-dessous correspondent à ce qui se trouve sur l'étiquette de votre ordonnance",
  "prescription-information": "Informations sur la prescription",
  "integrated-prescription-flow-description": "Entrez un numéro d'ordonnance récent pour connecter votre liste d'ordonnances au magasin spécifique que vous avez sélectionné",
  "prescribed-for": "Prescrit pour",
  "rx-number": "Numéro de réception",
  "last-filled-on": "Dernier rempli le",
  "last-ordered-on": "Dernière commande le",
  "auto-refills": "Recharges automatiques",
  "auto-refill": "Recharge automatique",
  "refills-left": "Recharges restantes",
  "quantity-dispensed": "Quantité distribuée",
  "days-supply": "Jours d'approvisionnement",
  "directions": "Les directions",
  "questions-about-medication": "Questions sur ce médicament?",
  "ask-your-pharmacist": "Demandez à votre pharmacien",
  "message": "Message",
  "refill": "Recharge",
  "child": "Enfant",
  "spouse": "Conjoint",
  "parent": "Parent",
  "grandparent": "Grand-parent",
  "grandchild": "Petit enfant",
  "pet": "Animaux",
  "partner": "Partenaire",
  "other": "Autre personne sous ma garde",
  "resources": "RESSOURCES",
  "medication-guides": "Guides des Médicaments",
  "how-to-take": "Comment prendre ce médicament",
  "patient-education-sheets": "Feuilles d'éducation des Patients",
  "side-effects-interactions": "Effets secondaires, interactions médicamenteuses",
  "prescription-written": "Date de Rx",
  "instructions": "instructions",
  "placeholder-step": "Pas d'espace réservé",
  "instructions-api": "L'accès à un compte Jotform existant dans Lumistry nécessite une clé API trouvée dans les paramètres JotForm.",
  "pdf-instructions": "Le .pdf ci-dessous contient un court guide étape par étape pour obtenir cette clé.",
  "open-pdf": "Ouvrir le PDF avec des instructions",
  "not-implemented": "Pas encore implémenté",
  "prescription-by": "Ordonnance par",
  "name-not-available": 'Nom non disponible',
  "birthdate-not-available": 'Date de naissance non disponible',
  "new-form": 'Nouvelle forme',
  "call-prescriber": "Prescripteur: ",
  "import-forms": "Importer des formulaires",
  "form": "Formulaire",
  "import-plan": "Plan d'importation",
  "number-of-forms": "Nombre de formulaires à importer",
  "link-expired": "Le lien a expiré ou est invalide, vous en aurez besoin d'un nouveau.",
  "reset-unsuccessful": "Échec de la réinitialisation",
  "order-submitted": "Votre commande est validée !",
  "order": "Commande",
  "picking-up-at": "Prise en charge à",
  "ready-today": "Prêt aujourd'hui entre",
  "hi": "Salut, {{firstName}}.",
  "create-your-password": "Veuillez créer votre mot de passe",
  "i-have-read-and-agree": "J'ai lu et accepté les",
  "pharmacy-terms-and-conditions": "Termes et Conditions",
  "privacy-policy": "Déclaration de confidentialité",
  "appointments-calendar": "Calendrier des rendez-vous",
  "terms-and-conditions-required": "Un accord sur les termes et conditions est requis",
  "privacy-policy-required": "L'accord de politique de confidentialité est requis",
  "new-patient": "Nouveau  patient",
  "i-am-a-new-patient": "Je suis un nouveau patient",
  "patient-details": "Détails du patient",
  "edit": "Modifier",
  "date-of-birth": "Date de naissance",
  "date-of-birth-short": "DDN",
  "phone-number": "Numéro de téléphone",
  "retrieving": "Récupération",
  "gender-text": "Genre",
  "language": "Langue",
  "conditions": "Conditions",
  "easy-open-bottle": "Bouteille facile à ouvrir",
  "med-sync": "Synchronisation Médicale",
  "photo-id": "Carte d'identité",
  "add-override": "Ajouter la remplacement",
  "new-appointment": "Nouveau rendez-vous",
  "download": "Télécharger",
  "chat": "Chatte",
  "photo": "Photo",
  "delete-image": "La suppression de cette image supprimera les informations de cette carte",
  "image": "Image",
  "notification": "Notification",
  "app-notification": "Notification de l'application",
  "list": "Liste",
  "providers": "Fournisseurs",
  "notes": "Notes",
  "internal-use-only": "Utilisation interne uniquement",
  "no-upcoming-appointments": "Aucun rendez-vous à venir",
  "no-refills-due": "Aucune recharge due",
  "to-get-started-choose": "Pour commencer,\nveuillez choisir l'une des options suivantes:",
  "face-id": "Identification faciale",
  "continuation-disclaimer": "En appuyant sur Continuer avec Apple, Google ou E-mail, vous acceptez cette Pharmacie",
  "medical-equipment-immunization": "Immunisations, équipement médical durable, préparation, consultation des patients, vaccin COVID-19",
  "services-offered": "Services offerts",
  "en-es-fr": "Anglais, Espagnol, Français",
  "languages-spoken": "Langues parlées à cet endroit",
  "holiday-hours-vary": "Les horaires des vacances peuvent varier.",
  "open-hours": "Ouvert aujourd'hui de: {{hours}}",
  "open-today": "Ouvert aujourd'hui 24h/24.",
  "store-hours": "Heures d'ouverture",
  "set-my-store": "Définir comme ma boutique",
  "upload-csv": "Télécharger csv",
  "personal-info": "Informations personnelles",
  "will-pickup-prescription-today": "Allez-vous récupérer votre ordonnance aujourd'hui?",
  "field-required": "Ce champ est obligatoire",
  "will-pickup-prescription-urgently": "Avez-vous besoin de retirer votre ordonnance de toute urgence?",
  "order-details": "Détails de la commande",
  "method": "Méthode",
  "note-for-pharmacy": "Note pour la pharmacie (facultatif)",
  "note-for-pharmacy-not-optional": "Note pour la pharmacie",
  "review": "Examen",
  "insurance-is-required": "Une assurance est requise",
  "insurance-change-last-refill": "Votre assurance a-t-elle changé depuis votre dernière recharge? *",
  "prescription-ready-each-month": "Voulez-vous que toutes vos ordonnances soient prêtes ensemble à la même heure chaque mois?",
  "prescriptions-is-required": "Une ordonnance est requise",
  "over-the-counter-medications": "Médicaments en vente libre",
  "please-review-messages-and-proceed": "Veuillez lire les messages ci-dessous et faites-nous savoir comment vous souhaitez procéder.",
  "how-to-proceed": "Comment procéder",
  "enter-patient-email": "Saisissez l'adresse e-mail du patient",
  "add-patient-request-submit": "Ajouter une demande de patient soumise",
  "enter-patient-information": "Saisir les informations du patient",
  "provide-an-email": "Veuillez fournir l'adresse e-mail que {{firstName}} {{lastName}} utilise pour le compte de {{firstName}} avec {{pharmacyName}}",
  "request-sent": "Demande envoyée à {{firstName}} {{lastName}}",
  "approval-needed": "L'approbation est nécessaire de cette personne dans les 14 jours",
  "person-has-an-account": "Si cette personne a un compte chez {{pharmacyName}}, elle recevra votre demande",
  "add-another-person": "Ajouter une autre personne",
  "add-another": "Ajouter un autre",
  "go-home": "Rentrer chez soi",
  "allow-pharmacy-contact-prescriber": "Autoriser la pharmacie à contacter le prescripteur",
  "continue-with-selected-items": "Continuer avec les éléments sélectionnés",
  "go-back": "Revenir en arrière",
  "go-back-update-order": "Revenir en arrière pour mettre à jour ma commande",
  "thank-you-for-choosing": "Merci d'avoir choisi",
  "return-to-order-details": "Return to order details",
  "add-people-under-care": "Ajouter des personnes dont vous avez la charge",
  "add-person": "Ajouter une personne",
  "unable-to-process-items": "Impossible de traiter un ou plusieurs éléments",
  "we-will-contact-prescriber": "Nous contacterons votre prescripteur pour autorisation sur les prescriptions suivantes. ",
  "please-review-the-messages-below": "Veuillez lire les messages ci-dessous et faites-nous savoir comment vous souhaitez procéder.",
  "ready-to-be-filled": "Prêt à remplir",
  "other-details": "Autres détails",
  "store-info": "Informations sur la boutique",
  "account-setup": "Configuration du compte",
  "close": "Fermer",
  "your-password-has-been-successfully-changed": "Votre mot de passe a été changé avec succès",
  "add-people-under-care-message-pt-1": "Vous pouvez trouver la personne que vous souhaitez ajouter en fournissant ses informations sur la page suivante",
  "add-people-under-care-message-pt-2": "Si la personne que vous ajoutez a plus de 18 ans, vous devrez également fournir l'adresse e-mail qu'elle utilise pour son compte chez",
  "other-information": "Autres informations",
  "notice": "Avis",
  "insurance": "Assurance",
  "update-weak-password": "Votre mot de passe ne répond pas aux exigences actuelles. Pour la sécurité et la protection de votre compte, veuillez en créer un nouveau.",
  "people-under-your-care": "Personnes sous votre garde",
  "people-under-your-care-empty-page": "Si vous gérez des ordonnances et des rendez-vous pour d'autres personnes ou animaux de compagnie, les avoir tous dans une seule liste est un moyen pratique de suivre les médicaments et les horaires de chacun, ce qui peut aider à éviter les renouvellements et les rendez-vous manqués",
  "add-person-or-pet": "Ajouter une personne ou un animal de compagnie",
  "medical-info": "Informations médicales",
  "none": "Rien",
  "refill-details": "Détails de la recharge",
  "success": "Succès",
  "prescription-no": "N° d'ordonnance",
  "name": "Nom",
  "status": "Statut",
  "submitted": "Soumis",
  "channel": "Canal",
  "delivery-method": "Méthode de Livraison",
  "refill-comments": "Refill Comments",
  "patient-info": "Commentaires de recharge",
  "details": "Des détails",
  "phone": "Téléphone fixe",
  "address": "Adresse",
  "email-text": "E-mail",
  "prescription": "Ordonnance",
  "patient": "Patient",
  "date-submitted": "Date de soumission",
  "allergies-colon": "Allergies: ",
  "medical-conditions": "Conditions médicales: ",
  "refill-past-due": "Recharge en retard:",
  "refill-due": "Recharge due:",
  "store": "Boutique",
  "app": "App",
  "allergies": "Allergies",
  "medications-empty-state-title": "Médicaments",
  "medications-empty-state-description": "L'ajout de vos médicaments facilitera le suivi et la demande de renouvellement d'ordonnances importantes qui vous aideront à rester en bonne santé !",
  "medications-reminders-empty-state-title": "Rappels de médicaments",
  "medications-reminders-empty-state-description": "Les rappels de médicaments sont un bon moyen de suivre vos médicaments, ce qui peut vous aider à éviter des risques inutiles et des maladies graves",
  "add-meds": "Ajouter des médicaments",
  "transfer-meds": "Transférer des médicaments d'une autre pharmacie",
  "pickup": "Ramasser",
  "select-an-option": "Veuillez sélectionner une option",
  "choose-from-the-list": "Veuillez choisir dans la liste des articles avant de cliquer sur le bouton de commande",
  "select-one-item": "Selecciona al menos un elemento",
  "show-more": "Montre plus",
  "street1": "Rue 1",
  "street2": "Rue 2",
  "gender-on-insurance": "Sexe (tel qu'il apparaît sur assurance)",
  "preferred-language": "Langue préférée",
  "notification-preferences": "Préférences de notification",
  "auto-voice-call": "Llamada de voz automatique",
  "edit-patient": "Modifier le patient",
  "show-less": "Montrer moins",
  "please-confirm-the-info-matches-prescription-label": "Veuillez confirmer que les informations ci-dessous correspondent à ce qui se trouve sur votre étiquette de prescription",
  "register-confirmation-heading": "Configuration d'un nouveau compte",
  "register-confirmation-body": "Un nouveau lien de configuration de compte a été envoyé à votre adresse e-mail. Veuillez vérifier votre e-mail et cliquez sur le lien fourni.",
  "orders-tab-empty-state-title": "Historique des commandes",
  "orders-tab-empty-state-description": "Passer une commande de recharge est facile depuis l'onglet Médicaments ! Après avoir passé une commande, les détails et le suivi iront ici",
  "start-an-order": "Commencer une commande",
  "show-locations": "Afficher les emplacements",
  "first-name-edit-user": "Prénom",
  "last-name-edit-user": "Nom de famille",
  "dob-edit-user": "Date de naissance (mm/dd/yyyy)",
  "information": "information",
  "intro": "Intro",
  "enter-email": "Entrez votre e-mail",
  "create-password": "Créer un mot de passe",
  "register-confirmation": "Confirmation d'enregistrement",
  "register": "S'inscrire",
  "reset-link": "Réinitialiser le lien",
  "reset-successful": "Réinitialisation réussie",
  "register-details": "Détails du registre",
  "phone-verification": "Vérification du téléphone",
  "patient-pharmacy-stores": "Patient-pharmacy-stores",
  "add-prescription": "Ajouter une prescription",
  "refill-guest": "Recharger l'invité",
  "home": "Maison",
  "settings": "Paramètres",
  "store-location": "Emplacement du magasin",
  "expiring-soon": "Expire bientôt",
  "schedule": "Calendrier",
  "find-time": "Trouvez un moment",
  "learn-more": "Apprendre encore plus",
  "next-available": "Suivant disponible",
  "not-available": "Actuellement indisponible",
  "minutes": "Minutes",
  "book-appointment": "Prendre rendez-vous",
  "upcoming": "A venir",
  "past": "Passé",
  "patient-insurance": "Assurance des patients",
  "barcode": "Code à barre",
  "patient-preferences": "Préférences des patients",
  "feedback-success": "Succès des commentaires",
  "people-under-care": "Les personnes dont vous avez la garde",
  "add-patient-under-care": "Ajouter un patient pris en charge",
  "add-patient-under-care-info": "Ajouter des informations sur le patient pris en charge",
  "add-patient-under-care-email": "Ajouter un e-mail patient sous soins",
  "add-patient-under-care-confirmation": "Ajouter un patient sous confirmation de soins",
  "patient-under-care-list": "Liste des patients sous soins",
  "on-hold": "En attente",
  "contact-info": "Coordonnées",
  "no-puc-access": "Vous n'êtes actuellement pas autorisé à accéder au dossier de ce patient. Pour y accéder, veuillez accéder aux paramètres de votre compte et ajoutez-le en tant que patient sous soins.",
  "automatic-prescription-flow": "Flux de prescription automatique",
  "success-automatic-prescription-flow": "Succès du flux de prescription automatique",
  "rejected-reason-unknown": "Raison rejetée inconnue",
  "select-person": "Sélectionnez le patient pour ce rendez-vous. Si vous ne les voyez pas dans la liste, vous devrez d'abord les ajouter.",
  "myself": "Moi-même",
  "confirm": "Confirmer",
  "error-loading-appointments": "Erreur lors du chargement des rendez-vous",
  "appointment-scheduled": "rendez-vous est pris",
  "booking-error": "Une erreur s'est produite lors de la tentative de prise de rendez-vous. Veuillez réessayer.",
  "error-loading-slots": "Erreur lors du chargement des créneaux de rendez-vous",
  "error-loading-patient-record": "Erreur lors du chargement du dossier patient",
  "error-updating-patient-record": "Erreur mise à jour du dossier du patient",
  "error-booking-appointment": "Erreur lors de la prise de rendez-vous",
  "when": "Quand",
  "who": "Qui",
  "where": "Où",
  "care-notes": "Notes d'entretien",
  "reschedule": "Replanifier",
  "cancel-appointment": "Annuler le rendez-vous",
  "error-loading-appointment": "Erreur lors du chargement du rendez-vous",
  "error-loading-appointment-type": "Erreur lors du chargement du type de rendez-vous",
  "refill-medications": "refill-medications",
  "refill-medical-infos": "refill-medical-infos",
  "refill-review": "refill-review",
  "refill-other-information": "refill-other-information",
  "refill-prescription-method": "refill-prescription-method",
  "refill-insurance-method": "refill-insurance-method",
  "refill-confirmation": "refill-confirmation",
  "refill-order-details": "refill-order-details",
  "good-morning": "Bonjour",
  "good-afternoon": "Bon après-midi",
  "good-evening": "Bonsoir",
  "you-are-chatting-with": "Vous discutez avec:",
  "new-message": "Nouveau message",
  "no-messages": "Pas de messages",
  "messages-have-a-question": "Avoir une question? Besoin de modifier une commande ? Envoyez-nous un message sécurisé.",
  "new-message-placeholder": "Message...",
  "general": "Général",
  "billing": "Facturation",
  "med-info": "Avec infos",
  "side-effects": "Effets secondaires",
  "message-subject-label": "Sélectionnez le sujet qui correspond le mieux à votre message :",
  "send": "Envoyer",
  "appointment-rescheduled": "le rendez-vous est reprogrammé",
  "error-rescheduling-appointment": "Erreur lors de la reprogrammation du rendez-vous",
  "cancel-appointment-confirmation": "Êtes-vous sûr de vouloir annuler ce rendez-vous?",
  "appointment-canceled": "le rendez-vous est annulé",
  "previously-scheduled": "Précédemment programmé:",
  "cancel-note": "Remarque: Annuler ce rendez-vous ne le supprimera pas des calendriers tiers auxquels il a été ajouté.",
  "cancel-error": "Une erreur s'est produite lors de la tentative d'annulation du rendez-vous. Veuillez réessayer.",
  "error-cancel-appointment": "Erreur lors de l'annulation du rendez-vous",
  "keep": "Garder",
  "of": "sur",
  "prescription-card-last-filled": "Dernière recharge",
  "prescription-card-last-ordered": "Dernière commande:",
  "prescription-card-refill-due": "Recharge due",
  "remove": "Retirer",
  "remove-patient": "Retirer patiente",
  "user-not-found": "Utilisateur non trouvé",
  "invalid-confirmation-code": "Code de confirmation invalide",
  "contact-support": "Contactez le support",
  "many-failed-attempts": "De nombreuses tentatives infructueuses",
  "not-authorized": "Pas autorisé",
  "retrieve-your-prescriptions": "Récupérer vos ordonnances",
  "other-meds": "Autres médicaments",
  "try-again": "Essayer à nouveau",
  "notify-you": "Te notifier",
  "reschedule-appointment": "Re-planifier un rendez-vous",
  "relationship-to-me": "Relation avec moi",
  "unable-to-submit-people-under-care-request": "Impossible de soumettre les personnes sous demande de soins.",
  "tomorrow": "Demain",
  "today": "Aujourd'hui",
  "canceled": "Annulé",
  "events": "événements",
  "error-field-number": "Le champ doit être un nombre",
  "no-appointments": "Vous n'avez actuellement aucun rendez-vous.\nSélectionnez un service pour commencer.",
  "no-services": "Aucun service n'est disponible à la réservation.",
  "video-header": "Vidéos",
  "videos": "Vidéos",
  "videos-filter": "FILTRE",
  "videos-clear": "Clair",
  "videos-all": "Tout",
  "videos-medication": "Médicament",
  "videos-condition": "Condition",
  "videos-administration": "Administration",
  "videos-general-health": "Santé générale",
  "videos-missing-medication-video": "Vidéo sur les médicaments manquants",
  "videos-search": "Recherche",
  "videos-share": "Partager",
  "videos-category": "Catégorie",
  "videos-title": "Titre",
  "videos-language-spanish": "Voir en Espagnol",
  "videos-language-english": "Voir en Anglais",
  "videos-language-french": "Voir en Français",
  "videos-duration": "Durée",
  "videos-url": "URL",
  "copied-to-clipboard": "Copié dans le presse-papier",
  "videos-med-guides": "Guides médicaux",
  "videos-transcript": "Transcription",
  "delete-confirmation-modal": "Êtes-vous sûr de vouloir supprimer le {{itemType}} : {{itemName}}?",
  "service": "Service",
  "create-appointment": "Créer un rendez-vous",
  "limit-booking-duration": "Limiter la durée totale de la réservation",
  "limit-future-bookings": "Limiter les réservations futures",
  "limit-booking-frequency": "Limiter la fréquence de réservation",
  "before-event": "Avant l'événement",
  "after-event": "Après l'événement",
  "into-future": "dans le futur",
  "within-date-range": "Dans une plage de dates",
  "add-limit": "Ajouter une limite",
  "calendar-days": "jours calendaires",
  "business-days": "jours ouvrés",
  "per-day": "Par jour",
  "per-week": "Par semaine",
  "per-month": "Par mois",
  "no-buffer-time": "Pas de temps de tampon",
  "content-box-title": "Affichage des tâches correspondant aux critères suivants:",
  "duration": "Durée",
  "time": "Temps",
  "check-later": "Veuillez revenir plus tard",
  "birthdate": "Date de naissance",
  "actions": "Actions",
  "service-record-enabled-tooltip": "Marquer le service comme indisponible pour la prise de rendez-vous",
  "service-record-disabled-tooltip": "Activer la prise de rendez-vous pour ce service",
  "copy-link-button-tooltip": "Copier dans le presse-papiers",
  "edit-service-record-tooltip": "Modifier le service",
  "delete-service-record-tooltip": "Supprimer le service",
  "selecting-all-pages": "Sélection de toutes les pages ({{count}} articles)",
  "select-all-pages": "Sélectionner toutes les pages",
  "selecting-current-page": "Sélection de cette page uniquement ({{count}} articles)",
  "select-current-page": "Sélectionnez cette page uniquement",
  "unselect-all": "Tout déselectionner",
  "others": "autres",
  "reorder": "Réorganiser",
  "order-refill": "Recharge de commande",
  "generic-bulk-confirmation-modal": "Êtes-vous sûr de vouloir {{bulkAction}} {{count}} {{type}} {{changeType}}?",
  "task": "tâche",
  "bulk-assignee-confirmation-modal": "Sélectionnez le destinataire pour {{count}} {{type}}.",
  "bulk-due-date-confirmation-modal": "Sélectionnez la date d'échéance pour {{count}} {{type}}.",
  "unresolved": "Non résolu",
  "resolved": "Résolu",
  "in_progress": "En cours",
  "on_hold": "En attente",
  "task-resolve": "Résolution des tâches",
  "mark-resolved": "Marquer comme résolu",
  "mark-in-progress": "Marquer comme en cours",
  "mark-flagged": "Marquer comme marqué/non marqué",
  "mark-unresolved": "Marquer comme non commencé",
  "mark-on-hold": "Marquer comme en attente",
  "change-assignee": "Modifier l'utilisateur assigné",
  "change-due": "Changer la date d'échéance",
  "task-in-progress": "Tâches en cours",
  "task-flag": "Drapeau de tâches",
  "assignee": "Cessionnaire",
  "not-started": "Non démarré",
  "over-the-counter": "En vente libre",
  "delete-task": "Supprimer la tâche",
  "venue": "Lieu",
  "private": "Privé",
  "public": "Public",
  "your-pharmacy": "Votre pharmacie",
  "edit-availability-record-tooltip": "Modifier la disponibilité",
  "delete-availability-record-tooltip": "Supprimer la disponibilité",
  "availability": "Disponibilité",
  "new-chat-message": "Nouveau message de conversation",
  "your-refill-is-ready": "Votre recharge est prête",
  "hi-your-refill-is-ready": "Salut{{firstName}}, votre recharge est prête à être récupérée.",
  "patient-is-required": "Patiente obligatoire!",
  "subject-is-required": "Le sujet est requis!",
  "message-or-attachment-is-required": "Un message ou une pièce jointe est requis!",
  "message-characters-limit-five-thousand": "La limite de caractères des messages est de 5000!",
  "find-template": "Trouver un modèle",
  "to": "Pour",
  "subject": "Sujet",
  "note-inbox-messages-should-be-clinical-in-nature": "Remarque: Les messages de la boîte de réception doivent être de nature clinique. L'envoi de messages marketing via cette plate-forme pourrait enfreindre la loi sur la protection des consommateurs par téléphone et les réglementations de la Federal Communications Commission. Ne demandez pas d'informations sur votre carte de crédit.",
  "all": "Tout",
  "unread": "Non lu",
  "unread-by-patient": "non lu par le patient",
  "includes-me": "M'inclut",
  "created-by-me": "Créé par moi",
  "last-seven-days": "Les 7 derniers jours",
  "filters": "Filtres",
  "clear": "Nettoyer",
  "text-formatting": "Formatage du texte",
  "send-attachment": "Envoyer la pièce jointe",
  "type-your-message": "Tapez votre message...",
  "mark-as-unread": "Masquer comme non lu",
  "chat-messages": "Messages de chat",
  "find-patient": "Trouver un patient",
  "new-subject": "Nouveau sujet",
  "add-mobile-number-send-message": "Ajouter un numéro de mobile pour envoyer un message",
  "add-mobile-number": "Ajouter un numéro de mobile",
  "newest": "Le plus récent",
  "oldest": "Le plus ancien",
  "a-z": "A-Z",
  "new-chat": "Nouvelle conversation",
  "inbox": "Boîte de réception",
  "auto": "Auto",
  "previous-week": "Semaine précédente",
  "current-week": "Semaine en cours",
  "next-week": "La semaine prochaine",
  "secure-message": "message sécurisé",
  "unable-to-process": "Impossible de traiter votre demande",
  "try-again-later": "Veuillez réessayer plus tard. Si vous avez besoin d'une assistance immédiate, veuillez contacter votre pharmacie.",
  "message-pharmacy": "Message pharmacie",
  "call-pharmacy": "Appeler la pharmacie",
  "tasks-grid-no-results-title": "Vos critères de recherche n'ont aucune tâche dans la période actuelle",
  "tasks-grid-no-results-subtitle": "Nous n'avons trouvé aucune tâche dans la période actuelle en fonction de votre vue et de vos filtres. Utilisez les boutons Précédent / Suivant pour naviguer dans le temps, ou le bouton Semaine en cours pour réinitialiser la vue par défaut.",
  "get-directions": "Obtenir des directions",
  "explore-other-locations": "Découvrez d'autres lieux offrant ce service",
  "error-getting-locations": "Erreur lors de la récupération des emplacements",
  "make-appointment": "Prendre rendez-vous",
  "find-service-at-location": "Rechercher des services à un autre emplacement",
  "get-more-stores": "Obtenez plus de magasins",
  "zip-code-is-not-valid": "Le code postal n'est pas valide",
  "street-1-is-not-valid": "La rue 1 n'est pas valide",
  "street-2-is-not-valid": "La rue 2 n'est pas valide",
  "allergies-are-not-valid": "Les allergies-ne-sont-pas-valides, un exemple correct est all1, all2, all3...",
  "conditions-are-not-valid": "Les conditions ne sont pas valides, un exemple correct est cond1,cond2,cond3...",
  "error-loading-patients-under-care": "Erreur lors du chargement des patients sous soins",
  "add-new-patient": "Ajouter un nouveau patient...",
  "after-adding-patient": "Après avoir ajouté un nouveau patient, veuillez retourner aux rendez-vous et sélectionner le service souhaité.",
  "pending-requests": "Demandes en attente:",
  "add-calendar-event": "Ajouter au calendrier",
  "by-application": "sur demande",
  "by-email": "Par email",
  "by-sms": "Par SMS",
  "by-voice": "Par la voix",
  "error-loading-patient": "Une erreur s'est produite lors de la tentative d'obtention du patient. Veuillez réessayer.",
  "in": "dans",
  "ago": "il y a",
  "month-short": "mois",
  "hour-short": "dans",
  "day-short": "dans",
  "missing-important-patient-record-data": "Le dossier du patient ne contient pas de données d'importation.",
  "google": "Google",
  "apple": "Apple",
  "outlook": "Outlook",
  "reset-filters": "Réinitialiser les filtres",
  "calendar": "Calendrier",
  "no-medication-guide": "Actuellement indisponible. Si vous avez des questions sur ce médicament, contactez votre pharmacie.",
  "appointment-panel": "Panneau de nomination",
  "age": "Âge",
  "confirmed": "Confirmé",
  "pending": "En attente",
  "rejected": "Rejeté",
  "service-description": "Description du service",
  "additional-info": "Informations supplémentaires",
  "more": "Plus",
  "less": "Moins",
  "month-capital": "Mois",
  "week-capital": "Semaine",
  "day-capital": "Jour",
  "dont-see-service": "Vous ne voyez pas le service dont vous avez besoin?",
  "service-category": "Catégorie de services",
  "team": 'Équipe',
  "personal": "Personnel",
  "reschedule-note": "Remarque : Reprogrammer ce rendez-vous ne le supprimera pas des calendriers tiers auxquels il a été ajouté.",
  "ordered": "Commandé",
  "reoccurrence-must-have-context": "Le champ de réapparition doit avoir un contexte de forme parentale",
  "start-date-lower-end-date": "La date de début doit être définie avant la date de fin.",
  "characters-count": "Les caractères comptent: {{count}}",
  "maxim-count-attachments": "Le nombre maximal de pièces jointes est de {{count}}.",
  "selecting-files-wrong": "Une erreur s'est produite lors de la sélection des fichiers.",
  "files-sending-error-try-again": "Une erreur s'est produite lors de l'envoi des fichiers. Veuillez réessayer.",
  "home-account-subheading": "Paramètres, assurance, aide, etc.",
  "home-medications-subheading": "Gérer les médicaments et commander des renouvellements",
  "home-messages-subheading": "Discutez en toute sécurité avec la pharmacie",
  "home-appointments-subheading": "Afficher ou planifier des services cliniques",
  "home-puc-subheading": "Ajouter une personne...",
  "create-form-first": "Veuillez d'abord créer un formulaire",
  "pharmacy": "Pharmacie de ville",
  "pharmacy-general-info": "Informations générales",
  "pharmacy-edit-general-info": "Modifier les informations générales",
  "pharmacy-general-details": "Détails",
  "pharmacy-general-info-limited-brand-name": "Limité à 16 caractères",
  "pharmacy-company-name": "Nom court pharmacie",
  "pharmacy-brand-full-name": "Nom complet de la pharmacie",
  "pharmacy-brand-admin": "Administrateur de marque",
  "pharmacy-parent-company": "Maison mère",
  "pharmacy-ncpdp": "NCPDP",
  "pharmacy-npi": "NPI",
  "pharmacy-pms": "PMS",
  "pharmacy-facebook": "Facebook",
  "pharmacy-instagram": "Instagram",
  "pharmacy-twitter": "Twitter",
  "pharmacy-contact-info": "Informations de contact",
  "pharmacy-edit-contact-info": "Modifier les coordonnées",
  "pharmacy-email-address": "Adresse e-mail",
  "pharmacy-phone-number": "Numéro de téléphone",
  "pharmacy-fax-number": "Numéro de fax",
  "pharmacy-mailing-address": "Adresse postale",
  "pharmacy-billing-address": "Adresse de facturation",
  "pharmacy-brand-name-length": "Le nom ne doit pas dépasser 16 caractères",
  "pharmacy-departments": "Départements",
  "pharmacy-names-details": "Des noms",
  "pharmacy-edit-department": "Modifier le département",
  "pharmacy-delete-department": "Voulez-vous supprimer le département?",
  "pharmacy-delete-department-info": "En confirmant vous supprimerez le département sélectionné.",
  "pharmacy-departments-name": "Département",
  "pharmacy-departments-hours": "Horaires du département",
  "pharmacy-departments-hours-subtitle": "Les heures par défaut pour tous les magasins. Les magasins individuels peuvent remplacer ces paramètres.",
  "pharmacy-departments-name-required": "Le nom du département est obligatoire.",
  "pharmacy-departments-add-hours": "Ajouter des heures",
  "pharmacy-departments-remove-hours": "Eliminar",
  "pharmacy-holiday-closures": "Fermetures de vacances",
  "pharmacy-holiday-closures-description": "Fermetures de magasins récurrentes annuelles.",
  "pharmacy-additional-closures": "Fermetures supplémentaires",
  "pharmacy-additional-closures-description": "Ce ne sont pas récurrents.",
  "pharmacy-additional-closures-active": "Active",
  "pharmacy-additional-name-required": "Le nom de fermeture est requis",
  "pharmacy-additional-date-required": "La date de fermeture est requise",
  "pharmacy-additional-hour-required": "L'intervalle de clôture est requis",
  "pharmacy-additional-label": "Étiquette",
  "new": "Nouvelle",
  "url-is-not-valid": "L'URL n'est pas valide",
  "ok": "D'accord",
  "upload": "Télécharger",
  "replace": "Remplacer",
  "front-photo-missing": "L'image avant est manquante!",
  "back-photo-missing": "L'image arrière est manquante!",
  "no-allergies": "Aucune allergie",
  "easy-open-bottle-caps-confirm": "Voulez-vous des capsules à ouverture facile ?",
  "allergy-info": "Confirmer que les informations sur les allergies sont à jour",
  "forms-fill-out": "Remplissez {{formsCount}} formulaires requis...",
  "forms-filled-in": "Remplissez les formulaires requis",
  "health-info": "Revoir les informations de santé liées",
  "insurance-card-reminder": "Apportez votre carte d'assurance",
  "pre-appointment-checklist": "Liste de contrôle avant rendez-vous",
  "add-patients-start-messaging": "Ajoutez vos patients pour commencer à envoyer des messages",
  "upload-patients-csv": "Télécharger des patients via CSV",
  "error-updating-appointment": "Erreur lors de la reprogrammation du rendez-vous",
  "message-body-is-required": "Le corps du message est requis",
  "verify-patient": "Vérifier le patient",
  "verify-patient-warning": "Veuillez vérifier le nom, la date de naissance et le numéro de téléphone de ce patient avant de divulguer des informations personnelles sur la santé",
  "no-forms-required": "Aucun formulaire n'était requis pour ce rendez-vous",
  "form-unavailable": "Le formulaire n'est plus disponible",
  "form-not-started": "Formulaire non démarré",
  "form-submitted": "Le formulaire a été soumis",
  "preparing-submission": "Préparation de la soumission",
  "download-will-begin": "Votre téléchargement va bientôt commencer.",
  "delete-submission-confirmation": "Êtes-vous sûr de vouloir supprimer cette soumission ?",
  "not-entered": "Non saisi",
  "submitted-on": "Soumis le",
  "download-pdf": "Télécharger le PDF",
  "submission-modal": "Modalité de soumission",
  "medical-information": "Medical information",
  "yes-easy-caps": "Yes, I prefer easy-open caps",
  "no-safety-caps": "No, I prefer safety caps",
  "easy-open-bottle-caps-description": "Federal regulations require that most medications be packaged with child-resistant “safety” caps. Your choice of easy-open caps indicates your desire to have medications dispensed with non-safety caps.",
  "checklist-complete": "Liste de contrôle terminée",
  "checklist-incomplete": "Veuillez compléter la liste de contrôle",
  "service-status-updated": "L'état du service a été mis à jour avec succès",
  "error-loading-form": "Erreur lors du chargement du formulaire",
  "account-not-found-heading": "Compte non trouvé",
  "account-not-found-subheading": "Si vous avez créé votre compte à l'aide de votre connexion Apple ou Google, veuillez vous connecter avec ce compte",
  "account-not-found-subheading-sso": "Si vous avez créé votre compte avec votre adresse e-mail, veuillez vous connecter avec votre e-mail",
  "invalid-email-format": "Format d'e-mail invalide",
  "close-confirmation-question": "Êtes-vous sûr de vouloir quitter?",
  "close-confirmation-text": "Ce formulaire est nécessaire pour planifier votre rendez-vous.",
  "close-confirmation-exit": "Sortie",
  "close-confirmation-keep-appointment": "Tenir les rendez-vous",
  "last-submission": "Dernière soumission",
  "no-submissions": "Aucun formulaire n'a été soumis",
  "search-forms": "Formulaires de recherche",
  "no-submissions-found": "Aucun résultat trouvé pour ",
  "password-reset-subheading": "Pour la sécurité et la protection de votre compte, veuillez créer un nouveau mot de passe.",
  "save-password": "Enregistrer le mot de passe",
  "venue-in-person": "En Personne",
  "venue-virtual": "Vidéo",
  "venue-over-the-phone": "Téléphone",
  "get-the-app": "Téléchargez",
  "edit-reschedule-this-appointment": "Modifier ou reporter ce rendez-vous",
  "cancel-this-appointment": "Annuler ce rendez-vous",
  "message-this-patient": "Envoyer un message à ce patient",
  "contact-pharmacy-for-policy-info": "Veuillez contacter votre pharmacie locale pour plus d'informations sur leur",
  "prescriptions-empty-state-paragraph-one": "L'ajout de vos médicaments facilitera le suivi et la demande de renouvellement de prescriptions importantes qui vous aideront à rester en bonne santé !",
  "prescriptions-empty-state-paragraph-two": "Pour ajouter vos ordonnances maintenant, appuyez sur le bouton ci-dessous. Si vous souhaitez transférer vos médicaments depuis une autre pharmacie, veuillez appuyer sur le lien ci-dessus.",
  "prescriptions-empty-state-button-text": "Ajouter des médicaments",
  "otc-empty-state-paragraph-one": "Informer votre pharmacie de tous les médicaments, vitamines et suppléments que vous prenez peut l'aider à détecter d'éventuelles interactions médicamenteuses, vous gardant ainsi en meilleure santé et en sécurité !",
  "otc-empty-state-paragraph-two": "Cela inclut tous les médicaments que vous utilisez et qui ne nécessitent pas d'ordonnance",
  "otc-empty-state-button-text": "Ajouter des médicaments en vente libre",
  "viewing-medications-for": "Affichage des médicaments pour :",
  "mark-unread": "Marquer comme non lu",
  "error-occurred-title": "Une erreur inattendue s'est produite",
  "error-occurred-description": "Si vous avez besoin d'une assistance immédiate, veuillez contacter votre pharmacie.",
  "contact-prescription-ready": "Nous vous contacterons lorsque votre ordonnance sera prête",
  "task-type": "Type",
  "task-status": "Statut",
  "task-assigned-to": "Assigné à",
  "task-priority": "Priorité",
  "task-created-by": "Créé par",
  "task-due-by": "Date d'échéance",
  "task-visibility": "Visibilité",
  "integration": "Integration",
  "api-host": "API Host",
  "server-address": "Server Address",
  "server-port": "Server Port",
  "api-key": "API Key",
  "setup-integration": "Setup Integration",
  "integration-missing": "Currently no integration setup",
  "edit-integration": "Edit Integration",
  "pharmacy-software": "Pharmacy Software",
  "confirming-lose-current-form": "En confirmant que vous perdrez les progrès actuels sur l'importation d'un formulaire.",
  "confirm-delete-availability": "Êtes-vous sûr de vouloir supprimer la disponibilité avec le nom: {{name}}?",
  "npi": "NPI",
  "hours": "Heures",
  "date": "date",
  "flagged": "Signalé",
  "assignee-required": "Le cessionnaire est requis",
  "access-private-public": "Accès: privé / public",
  "cannot-delete-task-type": "Impossible de supprimer les types de tâches qui sont actuellement utilisés",
  "cannot-edit-task-type": "Impossible de modifier le type de tâche par défaut",
  "trash": "Poubelle",
  "personal-task-assign": "Les tâches personnelles ne peuvent être affectées qu'au propriétaire / créateur de la tâche",
  "overdue": "En retard",
  "new-service": "Nouveau service",
  "remove-item": "Retirer l'objet",
  "download-all-submissions": "Télécharger toutes les soumissions",
  "no-submissions-download": "Il n'y a pas de soumissions à télécharger",
  "copy-link": "Copier le lien",
  "form-was-imported": "Le formulaire a été importé",
  "form-submission-not-deleting": "Les formulaires avec les soumissions peuvent ne pas être supprimés",
  "drag-change-order": "Faites glisser pour changer la commande",
  "api-required": "La clé API est requise",
  "high-priority": "Haute priorité",
  "recurring": "Récurrente",
  "delete-availability-in-use-tooltip": "Cette disponibilité est utilisée pour les services.\nVeuillez supprimer/modifier la disponibilité du service,\npuis revenir pour supprimer la disponibilité.",
  "photo-id-2": "Photo d'identité",
  "must-valid-domain": "Doit être un domaine valide",
  "no-access": "Pas d'accès",
  "confirm-to-make": "Êtes-vous sûr de vouloir faire ce formulaire",
  "confirm-delete-availability-implicit": "Êtes-vous sûr de vouloir supprimer la disponibilité?",
  "confirm-delete-service-implicit": "Êtes-vous sûr de vouloir supprimer le service?",
  "something-went-wrong-implicit": "Quelque chose c'est mal passé. Merci d'essayer plus tard.",
  "prescription-info": "Informations sur la prescription",
  "confirm-to-delete-name": "Êtes-vous sûr de vouloir supprimer le formulaire avec le nom: {{name}}?",
  "add-to-list": " Ajouter à la liste",
  "adding-prescriptions-for": "Ajout d'ordonnances pour ",
  "find-meds": "Trouver des Médicaments",
  "rx-number-must-be-numeric": "Veuillez saisir uniquement des chiffres",
  "no-notes-attached": "Aucune note n'est jointe à ce rendez-vous",
  "error-occurred-integration-save": "Une erreur s'est produite lors de la tentative d'enregistrement de l'intégration.",
  "error-occurred-integration-load-save": "Une erreur s'est produite lors de la tentative de chargement ou d'enregistrement de l'intégration. Veuillez réessayer.",
  "integration-saved": "Intégration enregistrée",
  "hide": "Cacher",
  "server-address-required": "L'adresse du serveur est requise",
  "start-time-required": "L'heure de début est requise",
  "end-time-required": "L'heure de fin est requise",
  "custom-filters": "Filtres personnalisés",
  "appointments-filters-info-title": "Affichage des rendez-vous correspondant aux critères suivants :",
  "service-name-info-box": "Nom du service défini sur ",
  "service-category-info-box": "Catégorie de service définie sur ",
  "appointment-notes-update-error": "Une erreur s'est produite lors de la tentative de mise à jour des notes de rendez-vous. Veuillez réessayer.",
  "applied-to-all-locations": "Appliqué à tous les emplacements",
  "add-image": "Ajouter une image",
  "add-file": "Ajouter le fichier",
  "pharmacy-npi-number": "Numéro NPI de la pharmacie",
  "have-second-insurance": "Avez-vous une deuxième assurance ?",
  "my-tasks": "Mes tâches",
  "access-denied": "Accès refusé",
  "check-back-later": "Veuillez vérifier plus tard ou contacter la pharmacie si vous avez des questions.",
  "contact-administration": "Contactez l'administrateur informatique si vous avez des questions",
  "profile-settings": "Paramètres du profil",
  "new-messages": "Nouveaux messages",
  "this-week": "Cette semaine",
  "this-month": "Ce mois-ci",
  "older": "Plus ancien",
  "time-sensitive": "Urgent",
  "switch-user": "Changer d'utilisateur",
  "audit-trail": "Piste d'audit",
  "automated-actions": "Actions automatisées",
  "help-support": "Aide et support",
  "internal-demos": "Démos internes",
  "vow-ivr": "Vœu IVR",
  "wellness-classes": "Cours de bien-être",
  "event-logs": "Journaux d'événements",
  "subscribe-to-webhooks": "Abonnez-vous aux Webhooks",
  "realtime-updates": "Mises à jour en temps réel",
  "enabled": "Activé",
  "disabled": "Désactivé",
  "overwrite-defaults": "Écraser les valeurs par défaut",
  "changed-insurance": "Votre assurance a-t-elle changé depuis votre dernière recharge ?",
  "notifications": "Notifications",
  "no-notifications": "Pas de notifications",
  "no-notifications-subtitle": "Nous vous avertirons lorsque quelque chose se produit",
  "add-manually": "Ajouter manuellement",
  "authentication-failed-generic": "authentification échouée",  
  "sending": "Envoi en cours...",
  "delivered": "Livré",
  "failed-to-send": "Échec de l'envoi",
  "retry": "Recommencez",
  "edit-rows": "Edit Ranse",
  "add-new-row": "Ajouter une nouvelle ligne",
  "every-month": "Chaque mois",
  "relationship-to-me-is-required": "Relation avec moi obligatoire",
  "at": "à",
  // Workflow Statuses
  "workflow-status-in-queue": "In queue",
  "workflow-status-out-of-stock": "Out of stock",
  "workflow-status-special-order": "Special order",
  "workflow-status-in-process": "In process",
  "workflow-status-awaiting-insurance-authorization": "Awaiting insurance",
  "workflow-status-awaiting-prescriber-authorization": "Awaiting prescriber",
  "workflow-status-ready-for-shipping": "Ready for shipping",
  "workflow-status-ready-for-delivery": "Ready for delivery",
  "workflow-status-partial-fill": "Partial fill",
  "workflow-status-partially-filled": "Partially filled",
  "workflow-status-delivery-in-progress": "Out for delivery",
  "workflow-status-ready-for-pickup": "Ready for pickup",
  "workflow-status-dispensed": "Dispensed",
  "workflow-status-mail": "Mail",
  "workflow-status-shipped": "Shipped",
  "workflow-status-delivered": "Delivered",
  "workflow-status-returned": "Returned",
  "workflow-status-returned-to-stock": "Returned to stock",
  "workflow-status-cancelled": "Canceled",
  "workflow-status-expired": "Expired",
  "workflow-status-discontinued": "Discontinued",
  "workflow-status-transferred": "Transferred",
  "workflow-status-insurance-refused": "Refused by insurance",
  "workflow-status-prescriber-refused": "Refused by prescriber",
  "workflow-status-undefined": "-",
  // Workflow Statuses Long
  "workflow-status-in-queue-message": "This Rx is in the refill queue",
  "workflow-status-out-of-stock-message": "This Rx is out of stock",
  "workflow-status-special-order-message": "This Rx is on special order",
  "workflow-status-in-process-message": "This Rx is in the process of refilling",
  "workflow-status-awaiting-insurance-authorization-message": "This Rx is awaiting insurance authorization",
  "workflow-status-awaiting-prescriber-authorization-message": "This Rx is awaiting prescriber authorization",
  "workflow-status-ready-for-shipping-message": "This Rx is filled and ready for shipping",
  "workflow-status-ready-for-delivery-message": "This Rx is filled and ready for delivery",
  "workflow-status-partial-fill-message": "This Rx was partially filled",
  "workflow-status-partially-filled-message": "This Rx was partially filled",
  "workflow-status-delivery-in-progress-message": "This Rx has been sent out for delivery",
  "workflow-status-ready-for-pickup-message": "This Rx is filled and ready for pickup",
  "workflow-status-dispensed-message": "This prescription has been dispensed",
  "workflow-status-mail-message": "This prescription has been mailed",
  "workflow-status-shipped-message": "This prescription has been shipped",
  "workflow-status-delivered-message": "This prescription has been delivered",
  "workflow-status-returned-message": "This prescription was returned",
  "workflow-status-returned-to-stock-message": "This prescription was returned to stock",
  "workflow-status-cancelled-message": "This prescription was canceled",
  "workflow-status-expired-message": "This prescription has expired",
  "workflow-status-discontinued-message": "This prescription has been discontinued",
  "workflow-status-transferred-message": "This prescription was transferred",
  "workflow-status-insurance-refused-message": "This prescription was refused by the insurance provider",
  "workflow-status-prescriber-refused-message": "This prescription was refused by the prescriber",
  "workflow-status-undefined-message": "Info unavailable",
  // Rx Statuses
  "rx-status-no-refills": "No more refills",
  "rx-status-expired": "Rx expired",
  "rx-status-too-soon": "Rx too soon",
  "rx-status-not-found": "Rx not found",
  "rx-status-controlled": "Controlled",
  "rx-status-narcotic": "Narcotic",
  "rx-status-transferred": "Transferred",
  "rx-status-discontinued": "Discontinued",
  "rx-status-on-hold": "On hold",
  "rx-status-deleted": "Rx deleted",
  "rx-status-fillable": "Refill OK",
  "rx-status-partially-filled": "Partial fill",
  "rx-status-out-of-stock": "Out of stock",
  "rx-status-special-order": "Special order",
  "rx-status-deceased": "Patient deceased",
  "rx-status-undefined": "-",
  // Rx Statuses Long
  "rx-status-no-refills-message": "This Rx has no more refills available",
  "rx-status-expired-message": "This prescription has expired",
  "rx-status-too-soon-message": "It is too soon to refill this prescription",
  "rx-status-not-found-message": "There is no record of this prescription number",
  "rx-status-controlled-message": "This prescription is a controlled substance",
  "rx-status-narcotic-message": "This prescription is a narcotic",
  "rx-status-transferred-message": "This prescription was transferred",
  "rx-status-discontinued-message": "This prescription has been discontinued",
  "rx-status-on-hold-message": "This prescription is on hold",
  "rx-status-deleted-message": "This prescription was deleted",
  "rx-status-fillable-message": "This prescription is good for refilling",
  "rx-status-partially-filled-message": "This prescription was partially filled",
  "rx-status-out-of-stock-message": "This prescription is out of stock",
  "rx-status-special-order-message": "This prescription is on special order",
  "rx-status-deceased-message": "The prescription belongs to a deceased patient",
  "rx-status-undefined-message": "Info unavailable",
  // Order Statuses
  "order-status-received": "Received",
  "order-status-filled": "Filled",
  "order-status-out-for-delivery": "Out for delivery",
  "order-status-ready-for-pickup": "Ready for pickup",
  "order-status-picked-up": "Picked up",
  "order-status-mailed": "Mailed",
  "order-status-shipped": "Shipped",
  "order-status-delivered": "Delivered",
  "order-status-return-to-stock": "Returned to stock",
  "order-status-info-unavailable": "Info unavailable, contact your pharmacy",
  "order-status-undefined": "Info not available, try again later",
  // Order Progress Statuses
  "order-progress-not-started": "Not started",
  "order-progress-started": "Started",
  "order-progress-completed": "Completed",
  "order-progress-undefined": "(n/a)",
  // Refill Status
  "refill-status-passed": "Passé",
  "refill-status-not-passed": "Echoué",
  "refill-status-filter-all": "Tout",
  //
  "preview": "Aperçu",
  "form-not-editable": "Ce formulaire n'est pas modifiable",
  "form-preview": "Aperçu du formulaire",
  "dob-age": "Date de naissance (âge)",
  "form-name": "Nom du formulaire",
  "mark-all-as-read": "Tout marquer comme lu",
  "mark-as-read": "Marquer comme lu",
  "continuation-disclaimer-register": "En appuyant sur Suivant, vous acceptez les conditions d'utilisation de cette pharmacie.",
  "inactive": "Inactif",
  "submissions": "Soumissions",
  "last-modified": "Dernière modification",
  "copy-url": "Copier l'URL",
  "allergies-info-not-available": "Informations sur les allergies non disponibles",
  "info-not-available": "Informations non disponibles",
  "active": "Actif",
  "no-edit": "Aucune modification",
  "imported": "Importé",
};

export default mapping;

