import { View } from 'react-native';
import { Form } from 'assets/layout';
import { makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';

interface InfoGroupProps {
  title: string;
  children?: JSX.Element | JSX.Element[];
}

function InfoGroup({ title, children }: InfoGroupProps) {
  const styles = useStyles();
  return (
    <View style={styles.group}>
      <Form.Row>
        <Form.Column style={styles.columnLine}>
          <Text style={styles.title}>{title}</Text>
        </Form.Column>
      </Form.Row>
      <View>{children}</View>
    </View>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.fonts.medium,
    fontSize: 16,
    marginBottom: 8,
  },
  group: {
    marginTop: 20,
  },
  columnLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#E1EAEF',
  },
}));

export default InfoGroup;
