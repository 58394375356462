import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent } from 'react';
import { useWindowDimensions, View } from 'react-native';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import { useEditFormStore } from './edit-form-store';

export const FormPreviewModal: FunctionComponent<FormPreviewModalProps> = ({
  showPreviewModal,
  onClose,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { height: viewportHeight } = useWindowDimensions();
  const { previewStatus, previewFormHtmlString } = useEditFormStore();

  return (
    <Modal
      show={showPreviewModal}
      size="lg"
      title={getText('form-preview')}
      cancelButtonProps={{
        logger: { id: 'cancel-preview-modal-button' },
        onPress: () => onClose(),
        style: {
          display: 'none',
        },
      }}
      okButtonProps={{
        hierarchy: 'secondary-gray',
        text: getText('close'),
        logger: { id: 'ok-preview-modal-button' },
        onPress: () => onClose(),
      }}
    >
      <View
        style={[
          styles.previewContainer,
          {
            maxHeight: viewportHeight * 0.75,
            height: viewportHeight * 0.75,
          },
        ]}
      >
        {previewStatus !== 'loading' && previewFormHtmlString ? (
          <iframe
            style={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: theme.palette.gray[500],
              borderRadius: 8,
              outline: 'none',
              boxSizing: 'border-box',
              boxShadow: 'none',
            }}
            height="100%"
            width="100%"
            srcDoc={previewFormHtmlString}
          ></iframe>
        ) : (
          <View
            style={{
              maxHeight: viewportHeight * 0.75,
              height: viewportHeight * 0.75,
            }}
          >
            <LoadingOverlay />
          </View>
        )}
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  previewContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
}));

interface FormPreviewModalProps {
  showPreviewModal: boolean;
  onClose: () => void;
}
