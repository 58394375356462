import { FunctionComponent, PropsWithChildren } from 'react';
import CredentialsSteps from './steps/CredentialsSteps';
import InstructionsStep from './steps/InstructionsStep';
import FormsImportStep from './steps/FormsImportStep';
import ImportPlanStep from './steps/ImportPlanStep';
import ImportForms from './steps/ImportForms';
import { getText } from 'assets/localization/localization';

interface ImportStepContent {
  id: string;
  title: string;
  component: FunctionComponent;
  buttonText: string;
  hasNavigationButtons: boolean;
}

export const INSTRUCTIONS_PDF_URL =
  'https://storage.googleapis.com/static.dev.lumistry.com/forms_static/forms-import--instructions--v1.pdf';

export const IMPORT_STEPPER_CONTENT: ImportStepContent[] = [
  {
    id: 'instructions',
    title: getText('instructions'),
    component: InstructionsStep,
    buttonText: getText('next'),
    hasNavigationButtons: true,
  },
  {
    id: 'credentials',
    title: getText('credentials'),
    component: CredentialsSteps,
    buttonText: getText('next'),
    hasNavigationButtons: true,
  },
  {
    id: 'formsImport',
    title: getText('import'),
    component: FormsImportStep,
    buttonText: getText('next'),
    hasNavigationButtons: true,
  },
  {
    id: 'importPlan',
    title: getText('import-plan'),
    component: ImportPlanStep,
    buttonText: getText('import'),
    hasNavigationButtons: true,
  },
  {
    id: 'importForms',
    title: getText('import-forms'),
    component: ImportForms,
    buttonText: getText('close'),
    hasNavigationButtons: false,
  },
];
