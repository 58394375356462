import {
  IServerSideDatasource,
  IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { AxiosInstance } from 'axios';
import { DataGridRemoteResponse } from '../../types';

import { DataGridService } from '../service/DataGridService';

export class BaseRemoteDatasource
  extends DataGridService
  implements IServerSideDatasource
{
  constructor(remoteUrl: string, axiosInstance?: AxiosInstance) {
    super(remoteUrl, axiosInstance);
  }

  async getRows(params: IServerSideGetRowsParams): Promise<void> {
    const { request, api, columnApi, fail, context } = params;

    const requestData = context?.transformRequest
      ? context.transformRequest(this.transformDataGridRequest(request))
      : this.transformDataGridRequest(request);

    try {
      const response =
        await this.makeDataRequest<DataGridRemoteResponse>(requestData);

      const responseToHandle = context?.transformResponse
        ? context.transformResponse(response)
        : response;
      this.handleResponse(responseToHandle, params);

      // notify ag grid with its native event
      const event = {
        type: 'successCallback',
        api: api,
        columnApi: columnApi,
        responseToHandle,
      };
      api.dispatchEvent(event);
    } catch (error) {
      // notify ag grid with its native event
      fail();
      const event = {
        type: 'failCallback',
        api: api,
        columnApi: columnApi,
        error,
      };
      api.dispatchEvent(event);
      console.error(error);
    }
  }

  handleResponse<T extends unknown>(
    response: T,
    params: IServerSideGetRowsParams,
  ) {
    params.success({
      rowData: (response as DataGridRemoteResponse).rows,
    });
  }
}
