export const pharmacySoftwares = [
  {
    value: 'TeleManagerXMLv2',
    label: 'PMS Simulator',
    form_fields: ['server_address', 'server_port'],
    overwrite_form_fields: [],
    fields: ['server_port'],
  },
  {
    value: 'LibertyV2',
    label: 'Liberty',
    form_fields: ['api_key', 'npi', 'is_subscribed_to_webhooks'],
    overwrite_form_fields: ['server_address', 'auth_user', 'auth_secret'],
    fields: [
      'api_key',
      'auth_user',
      'npi',
      'auth_secret',
      'is_subscribed_to_webhooks',
    ],
  },
];
