import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Item, Menu, Separator } from 'react-contexify';
import { StyleSheet, View } from 'react-native';
import { Icon } from 'assets/components/icon';
import { Text } from 'assets/components/text';
import { CheckIcon } from 'assets/icons';
import theme, { getSpacing } from 'assets/theme';
import { TaskSortBy, useTasksGridState } from './tasks-grid-store';
import { TaskSortOrder } from '@digitalpharmacist/tasks-service-client-axios';
import { setOrder, setSortBy } from './tasks-grid-actions';
import { getText } from 'assets/localization/localization';

export const MenuOptionItem = (props: {
  label: string;
  selected?: boolean;
}) => {
  return (
    <View style={[styles.menuOptionContainer]}>
      <Text
        style={[
          styles.menuOptionLabel,
          {
            color: props.selected
              ? theme.palette.primary[500]
              : theme.palette.gray[900],
          },
        ]}
        selectable
      >
        {props.label}
      </Text>
      <View
        style={{
          alignSelf: 'flex-end',
        }}
      >
        <Icon
          icon={CheckIcon}
          color={props.selected ? theme.palette.primary[500] : 'transparent'}
          size={24}
        ></Icon>
      </View>
    </View>
  );
};

export const TasksSortingContextMenu: FunctionComponent<
  PropsWithChildren<TasksSortingContextMenuProps>
> = ({ menuId }) => {
  const { sortBy, order } = useTasksGridState();
  return (
    <Menu id={menuId}>
      <Item onClick={() => setSortBy(TaskSortBy.DueDate)}>
        <MenuOptionItem
          label={getText('sort-by-due-date')}
          selected={sortBy === TaskSortBy.DueDate}
        />
      </Item>
      <Item onClick={() => setSortBy(TaskSortBy.Assignee)}>
        <MenuOptionItem
          label={getText('sort-by-assignee')}
          selected={sortBy === TaskSortBy.Assignee}
        />
      </Item>
      <Item onClick={() => setSortBy(TaskSortBy.Summary)}>
        <MenuOptionItem
          label={getText('sort-by-task-summary')}
          selected={sortBy === TaskSortBy.Summary}
        />
      </Item>
      <Separator />
      <Item onClick={() => setOrder(TaskSortOrder.Asc)}>
        <MenuOptionItem
          label={getText('ascending')}
          selected={order === TaskSortOrder.Asc}
        />
      </Item>
      <Item onClick={() => setOrder(TaskSortOrder.Desc)}>
        <MenuOptionItem
          label={getText('descending')}
          selected={order === TaskSortOrder.Desc}
        />
      </Item>
    </Menu>
  );
};

export interface TasksSortingContextMenuProps {
  menuId: string;
}

const styles = StyleSheet.create({
  menuOptionContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: getSpacing(1),
    alignItems: 'center',
  },
  menuOptionLabel: {
    color: theme.palette.gray[900],
    flexGrow: 1,
  },
});
