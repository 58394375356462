import {
  RxStatus,
  WorkflowStatus,
} from '@digitalpharmacist/prescription-service-client-axios';
import { getText, translations } from 'assets/localization/localization';

export const getWorkflowStatusText = (status?: WorkflowStatus) => {
  return getGenericStatusText(status, 'workflow-status-');
};

export const getWorkflowStatusMessageText = (status?: WorkflowStatus) => {
  return getGenericStatusText(status, 'workflow-status-', '-message');
};

export const getRxStatusText = (rxStatus?: RxStatus) => {
  return getGenericStatusText(rxStatus, 'rx-status-');
};

export const getRxStatusMessageText = (rxStatus?: RxStatus) => {
  return getGenericStatusText(rxStatus, 'rx-status-', '-message');
};

const getGenericStatusText = (
  status?: string,
  keyPrefix = '',
  keyPostfix = '',
) => {
  if (!status) status = 'undefined';

  const normalizeStatus = status.replace('_', '-');

  const textKey = `${keyPrefix}${normalizeStatus}${keyPostfix}` as translations; // eslint-disable-line @typescript-eslint/consistent-type-assertions

  const rxStatusText = getText(textKey);

  if (!rxStatusText) return textKey;

  return rxStatusText;
};
