import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';
import { CurrentStep, Steps } from './forms-import-stepper.types';
import { getText } from 'assets/localization/localization';

type Actions = {
  reset: () => void;
};

const initialState: FormsImportStepperState = {
  status: 'idle',
  isOpen: false,
  currentStep: {
    id: 'instructions',
    index: 0,
    title: getText('instructions'),
  },
  steps: {
    credentials: {
      apiKey: '',
      customDomain: '',
      invalid: true,
    },
    formsImport: {
      userForms: [],
      invalid: true,
    },
    formsPlan: {
      importForms: [],
      forms: [],
    },
    importedForms: {
      importForms: [],
    },
  },
};

export const useFormsImportStepperState = create<
  FormsImportStepperState & Actions
>((set) => ({
  ...initialState,
  reset: () => {
    set(initialState);
  },
}));

interface FormsImportStepperState {
  currentStep: CurrentStep;
  isOpen: boolean;
  status: AsyncStatus;
  steps: Steps;
  error?: ApiError;
}
