import { FormBuilderPostData } from 'react-form-builder2';
import {
  FormAccessType,
  FormStatus,
  FormWidget,
  UpdateFormDataDto,
} from '@digitalpharmacist/forms-service-client-axios';
import FormsService from '../../api/FormsService';
import { FormsDrawerNavigationProp } from '../../layout/FormsDrawer';
import { useEditFormStore } from './edit-form-store';
import { logError } from 'assets/logging/logger';
import { useAppStateStore } from '../../store/app-store';
import {
  createFormWidget,
  FormElement,
  generateFormBuilderData,
} from '../../forms/forms-builder/form-builder-utils';
import { ElementKeys } from '../../forms/forms-builder/toolbox';
import { useToast } from '../../common/hooks/useToast';
import { ampli } from '../../common/ampliPharmacist';
import { useUserState } from '../../store/user-store';

// TODO: review these 2 lines above. These are constants, will never change when the state changes
const pharmacyId = useAppStateStore.getState().pharmacyId;
const locationId = useAppStateStore.getState().locationId;

enum FormStateUpdateType {
  Full,
  ThankYouMessage,
  AccessType,
  Status,
  Name,
  Questions,
}

const errorOccurred = (error: any, errorMessage?: string) => {
  const { toast } = useToast();
  const message = errorMessage
    ? errorMessage
    : 'An error occurred while trying to load a form. Please try again.';

  logError(error);
  useEditFormStore.setState({
    error: {
      message: message,
    },
    status: 'error',
  });

  toast('Error', { type: 'error', content: message });
};

const updateForm = async (
  formId: string,
  updateForm: UpdateFormDataDto,
  formStateUpdateType: FormStateUpdateType,
) => {
  useEditFormStore.setState({ error: undefined, status: 'loading' });
  const { toast } = useToast();

  try {
    const response = await FormsService.updateForm(
      locationId,
      formId,
      updateForm,
    );

    getForm(formId, formStateUpdateType);
    toast('Form saved', { type: 'success' });
  } catch (error) {
    errorOccurred(error);
  }
  return;
};

export const changeFormsStatus = async (
  formId: string,
  newStatus: FormStatus,
) => {
  await updateForm(
    formId,
    {
      status: newStatus,
    },
    FormStateUpdateType.Status,
  );
};

export const changeFormsAccessType = async (
  formId: string,
  accessType: FormAccessType,
) => {
  await updateForm(
    formId,
    {
      formAccessType: accessType,
    },
    FormStateUpdateType.AccessType,
  );
};

export const changeFormName = async (formId: string, newName?: string) => {
  await updateForm(
    formId,
    {
      name: newName,
    },
    FormStateUpdateType.Name,
  );
};

export const changeFormThankYouMessage = async (
  formId: string,
  thankYou: { title?: string; subtitle?: string },
) => {
  await updateForm(
    formId,
    {
      thankYouTitle: thankYou.title,
      thankYouSubtitle: thankYou.subtitle,
    },
    FormStateUpdateType.ThankYouMessage,
  );
};

export const updateFormQuestions = async (
  formId: string,
  originalWidgets: FormWidget[],
  formBuilderElements: FormElement[],
) => {
  ampli.formEdited({
    formEditedTime: new Date().toISOString(),
    formEditedType: '',
    formName: useEditFormStore.getState().form?.title || '',
    formType: '',
    userid: useUserState.getState().data?.id || '',
  });

  await updateForm(
    formId,
    {
      questions: generateFormQuestions(originalWidgets, formBuilderElements),
    },
    FormStateUpdateType.Questions,
  );

  useEditFormStore.setState({ saveButtonEnabled: false });
};

export const generateFormQuestions = (
  originalWidgets: FormWidget[],
  formBuilderElements: FormElement[],
): FormWidget[] => {
  const updatedWidgets: FormWidget[] = [];
  for (const element of formBuilderElements) {
    const originalQuestion = originalWidgets.find((q) => q.qid === element.id);
    if (originalQuestion) {
      if (element.required !== undefined) {
        originalQuestion.required = element.required;
      }

      if (element.key == ElementKeys.Header) {
        originalQuestion.title = element.props?.header;
        originalQuestion.subtitle = element.props?.subheader;
      }

      if (element.label) {
        originalQuestion.widgetLabel = element.label;
      }

      if (element.options) {
        originalQuestion.options = element.options.map((option) => {
          return { label: option.text };
        });
      }

      if (element.optionLabel) {
        originalQuestion.optionLabel = element.optionLabel;
      }

      if (element.maxValue) {
        originalQuestion.maxValue = element.maxValue;
      }

      if (element.columnOptions) {
        originalQuestion.columnOptions = element.columnOptions.map((option) => {
          return { label: option.text };
        });
      }

      if (element.rowOptions) {
        originalQuestion.rowOptions = element.rowOptions.map((option) => {
          return { label: option.text };
        });
      }

      updatedWidgets.push(originalQuestion);
    } else {
      updatedWidgets.push(createFormWidget(element));
    }
  }

  return updatedWidgets;
};

export const deleteForm = async (
  formId: string,
  navigation: FormsDrawerNavigationProp,
) => {
  useEditFormStore.setState({ error: undefined, status: 'loading' });

  try {
    const response = await FormsService.deleteForm(locationId, formId);

    useEditFormStore.setState({
      status: 'idle',
    });
    navigation.navigate('forms-list');
  } catch (error) {
    errorOccurred(error);
  }
};

export const clearFormState = () => {
  useEditFormStore.setState({
    form: undefined,
  });
};

export const getFormPreview = async (
  formId: string,
  questions: FormWidget[],
) => {
  useEditFormStore.setState({
    error: undefined,
    previewStatus: 'loading',
    previewFormHtmlString: undefined,
  });

  try {
    const response = await FormsService.getFormPreview(locationId, formId, {
      questions,
    });

    useEditFormStore.setState({
      previewStatus: 'idle',
      previewFormHtmlString: response,
    });
  } catch (error) {
    errorOccurred(error);
  }
};

export const getFormPreviewWithData = async (formId: string) => {
  useEditFormStore.setState({
    previewStatus: 'loading',
  });

  try {
    const formResponse = await FormsService.getForm(locationId, formId);
    const previewResponse = await FormsService.getFormPreview(
      locationId,
      formId,
      { questions: formResponse.questions },
    );

    useEditFormStore.setState({
      previewStatus: 'idle',
      previewFormHtmlString: previewResponse,
    });
  } catch (error) {
    errorOccurred(error);
  }
};

export const setEditedFormBuilder = (formBuilderData: FormBuilderPostData) => {
  useEditFormStore.setState({
    editedFormBuilder: formBuilderData,
  });
};

export const getForm = async (
  formId: string,
  formStateUpdateType: FormStateUpdateType = FormStateUpdateType.Full,
) => {
  useEditFormStore.setState({
    error: undefined,
    status: 'loading',
  });

  try {
    const response = await FormsService.getForm(locationId, formId);

    // we need to refresh only the specific properties that have been updated
    // if we don't already have a form in store, use what we get from the
    // backend as fallback
    let newForm = useEditFormStore.getState().form || response;

    switch (formStateUpdateType) {
      case FormStateUpdateType.AccessType:
        newForm.formAccessType = response.formAccessType;
        break;
      case FormStateUpdateType.Name:
        newForm.title = response.title;
        break;
      case FormStateUpdateType.Status:
        newForm.status = response.status;
        break;
      case FormStateUpdateType.ThankYouMessage:
        newForm.thankYouTitle = response.thankYouTitle;
        newForm.thankYouSubtitle = response.thankYouSubtitle;
        break;
      case FormStateUpdateType.Questions:
        newForm.questions = response.questions;
        break;
      case FormStateUpdateType.Full:
        newForm = response;
        break;
    }

    switch (formStateUpdateType) {
      case FormStateUpdateType.AccessType:
      case FormStateUpdateType.Name:
      case FormStateUpdateType.Status:
      case FormStateUpdateType.ThankYouMessage:
        useEditFormStore.setState({
          form: newForm,
          status: 'idle',
        });
        break;
      case FormStateUpdateType.Questions:
      case FormStateUpdateType.Full:
        useEditFormStore.setState({
          form: newForm,
          status: 'idle',
          editedFormBuilder: {
            task_data: generateFormBuilderData(newForm.questions || []),
          },
        });
        break;
    }
  } catch (error: any) {
    errorOccurred(error);
  }
};

export const setSaveButtonEnabled = (saveButtonEnabled: boolean) => {
  useEditFormStore.setState({ saveButtonEnabled });
};
