import { View, TouchableOpacity } from 'react-native';
import { FunctionComponent, useMemo } from 'react';
import { getText } from 'assets/localization/localization';
import { Text } from 'assets/components/text';
import { ChatBox, ChatBoxProps } from './ChatBox';
import { getSpacing, makeStyles } from 'assets/theme';
import { User } from 'react-native-gifted-chat';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  formatDate,
} from '../../../common/datetime-utils';
import { IMessageExtended } from '../types';

export const ConversationBox: FunctionComponent<ConversationBoxProps> = ({
  messages,
  user,
  isOpen,
  subject,
  isViewed,
  lastMessageTime,
  onHeaderClick,
  onMarkUnread,
  ...restProps
}) => {
  const styles = useStyles();

  const sortedMessages = useMemo(() => {
    return messages.sort((a, b) => {
      return b.createdAt.valueOf() - a.createdAt.valueOf();
    });
  }, [messages]);

  const header = (
    <TouchableOpacity onPress={onHeaderClick}>
      <View style={styles.header}>
        <Text selectable style={!isViewed && styles.unread}>
          {subject}
        </Text>
        <View style={styles.statusWrapper}>
          {isOpen ? (
            <TouchableOpacity
              onPress={onMarkUnread}
              style={styles.middleStatus}
            >
              <Text style={!isViewed && styles.unread}>
                {getText('mark-as-unread')}
              </Text>
            </TouchableOpacity>
          ) : null}

          {isOpen ? (
            <Text style={!isViewed && styles.unread}>
              {formatDate(lastMessageTime, DEFAULT_TIME_FORMAT)}
            </Text>
          ) : (
            <Text style={[styles.statusTime, !isViewed && styles.unread]}>
              {formatDate(lastMessageTime, DEFAULT_TIME_FORMAT)} &nbsp;{' '}
              {formatDate(lastMessageTime, DEFAULT_DATE_FORMAT)}
            </Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );

  if (isOpen) {
    return (
      <View style={styles.wrapper}>
        {header}
        <ChatBox messages={sortedMessages} user={user} {...restProps} />
      </View>
    );
  } else {
    return <View style={styles.headerWrapper}>{header}</View>;
  }
};

interface ConversationBoxProps extends ChatBoxProps {
  messages: IMessageExtended[];
  user: User;
  isOpen: boolean;
  subject: string;
  isViewed: boolean;
  lastMessageTime: string;
  conversationId: string;
  onHeaderClick: () => void;
  onMarkUnread: () => void;
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    borderColor: theme.palette.gray[300],
    borderWidth: 1,
    borderRadius: 4,
    shadowOffsetWidth: 1,
    shadowOffsetHeight: 1,
    shadowRadius: 10,
    shadowOpacity: 0.2,
  },
  headerWrapper: {
    borderWidth: 1,
    borderColor: theme.palette.gray[200],
    borderRadius: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(1),
  },
  header: {
    ...theme.fonts.regular,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: theme.getSpacing(2),
    height: 40,
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.gray[200],
    color: theme.palette.gray[900],
  },
  statusWrapper: {
    flexDirection: 'row',
  },
  statusTime: {
    marginLeft: theme.getSpacing(2),
    color: theme.palette.gray[700],
  },

  unread: {
    fontWeight: 'bold',
  },

  middleStatus: {
    marginRight: getSpacing(1),
  },
}));
