import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import {
  PATIENT_API_BASE_URL,
  PRESCRIPTION_API_BASE_URL,
} from '../common/constants';
import {
  PharmacistApi as PrescriptionServiceClient,
  // TODO: expose the getPrescriptionsByPatientId in PharmacistApi on BE sdk
  PatientApi as PrescriptionPatientClient,
  RefillItemDto,
  RefillSubmittedDto,
} from '@digitalpharmacist/prescription-service-client-axios';
import { RefillSidebarDetails } from '../screens/refill-submissions/refill-submission-types';
import { DefaultApi as PatientService } from '@digitalpharmacist/patient-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { SearchableDatasourceService } from './SearchableDatasourceService';

export interface IPrescriptionService {
  findRefills(locationId: string): Promise<RefillItemDto[]>;
}
const patientService = new PatientService(undefined, PATIENT_API_BASE_URL);

export class PrescriptionService
  extends BaseApiService
  implements IPrescriptionService
{
  private prescriptionServiceClient: PrescriptionServiceClient;
  private prescriptionPatientServiceClient: PrescriptionPatientClient;
  private searchableDatasourceService: SearchableDatasourceService;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);

    this.prescriptionServiceClient = new PrescriptionServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
    this.prescriptionPatientServiceClient = new PrescriptionPatientClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );

    this.searchableDatasourceService = new SearchableDatasourceService(
      baseUrl,
      config,
      enableAuth,
      baseConfig,
    );
  }

  getRefillSubmissionsDatasource(locationId: string) {
    return this.searchableDatasourceService.getSearchableDatasource(
      `/locations/${locationId}/refills/items`,
    );
  }

  async findPrescriptionRefills(
    locationId: string,
  ): Promise<RefillSubmittedDto[]> {
    const prescriptions =
      await this.prescriptionServiceClient.refillsFindByLocation(locationId);

    return prescriptions.data.results;
  }

  fillPatientDetailsSidebar = async (
    locationId: string,
    selectedRefillSubmission: RefillSubmittedDto,
  ): Promise<RefillSidebarDetails> => {
    const patientServiceResponse =
      await patientService.locationPatientRecordFindOne(
        locationId,
        selectedRefillSubmission.patient_id,
      );

    return {
      patientDetails: patientServiceResponse.data,
      prescriptionDetails: selectedRefillSubmission,
    };
  };

  async findRefills(locationId: string): Promise<RefillItemDto[]> {
    const response =
      await this.prescriptionServiceClient.refillsFindByLocation(locationId);
    return response.data.results;
  }

  async findPrescriptions(locationId: string, locationPatientRecordId: string) {
    const response =
      await this.prescriptionPatientServiceClient.prescriptionFindAll(
        locationPatientRecordId,
        locationId,
      );
    return response.data;
  }
}

export default new PrescriptionService(
  PRESCRIPTION_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  true,
);
