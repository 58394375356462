import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useFormContext, RegisterOptions, Controller } from 'react-hook-form';
import { PlatformType } from '../types';
import { View } from 'react-native';
import { DropdownSelectNative } from './DropdownSelectNative';
import { DropdownSelectWeb } from './DropdownSelectWeb';
import { Icon } from '../icon';
import { AlertTriangleIcon } from '../../icons';
import theme, { getSpacing, makeStyles } from '../../theme';
import { Text } from '../text';
import { Platform } from 'react-native';

export const DropdownSelect: FunctionComponent<
  PropsWithChildren<DropdownSelectProps>
> = ({
  platform = Platform.OS,
  label,
  disabled = false,
  options,
  fieldName,
  placeholder,
  isSearchable,
  navBarDropdown,
  fontColor,
  labelInlined = false,
  rules,
}) => {
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('Dropdown/Select field must have a parent form context');
  }

  const { control, formState } = formContext;
  const error = formState.errors[fieldName];

  const styles = useStyles();

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field: {} }) => (
        <>
          {platform === 'web' ? (
            <DropdownSelectWeb
              label={label}
              disabled={disabled}
              options={options}
              fieldName={fieldName}
              formContext={formContext}
              placeholder={placeholder}
              isSearchable={isSearchable}
              navBarDropdown={navBarDropdown}
              fontColor={fontColor}
            />
          ) : (
            <DropdownSelectNative
              label={label}
              options={options}
              disabled={disabled}
              fieldName={fieldName}
              formContext={formContext}
              labelInlined={labelInlined}
            />
          )}
          {!!error && (
            <>
              <View style={styles.container}>
                <View style={styles.icon}>
                  <Icon
                    icon={AlertTriangleIcon}
                    color={theme.palette.error[600]}
                    size={16}
                  />
                </View>
                <Text
                  testID={DropdownSelectIDs.errorMessage}
                  style={styles.errorMessage}
                >
                  {error.message}
                </Text>
              </View>
            </>
          )}
        </>
      )}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: getSpacing(1),
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
  },

  icon: {
    marginRight: theme.getSpacing(0.5),
  },
}));

export interface DropdownSelectProps {
  platform?: PlatformType;
  label?: string;
  // Disabled property is only supported for the web platform
  disabled?: boolean;
  options: Array<{ label: string; value: string }>;
  fieldName: string;
  placeholder?: string;
  isSearchable?: boolean;
  navBarDropdown?: boolean;
  fontColor?: string;
  labelInlined?: boolean;
  rules?: RegisterOptions;
}

export const DropdownSelectIDs = {
  label: 'dropdown-label',
  errorMessage: 'dropdown-field-error-message',
};
