import React, { FunctionComponent, PropsWithChildren } from 'react';
import { View, StyleSheet } from 'react-native';
import { useFormContext } from 'react-hook-form';
import { Form } from 'assets/layout';
import theme, { getSpacing, makeStyles } from 'assets/theme';
import 'react-datepicker/dist/react-datepicker.css';
import { Text } from 'assets/components/text';
import './../../components/react-datepicker-overrides.css';
import { CheckboxField } from 'assets/components/checkbox';
import { DropdownSelect } from 'assets/components/dropdown-select';
import { DateTimePickerField } from '../../components/DateTimePickerField';
import { isDateBefore } from '../../common/datetime-utils';
import { zIndexAuto } from '../../common/theme';
import { getText } from 'assets/localization/localization';

const getIntervalOptions = (type: 'weekly' | 'monthly') => {
  if (type == 'weekly') {
    return [
      { label: getText('every-week'), value: WeeklyInterval.EveryWeek },
      {
        label: `${getText('every-n-weeks', {
          number: 2,
        })}`,
        value: WeeklyInterval.EveryTwoWeeks,
      },
      {
        label: `${getText('every-n-weeks', {
          number: 3,
        })}`,
        value: WeeklyInterval.EveryThreeWeeks,
      },
      {
        label: `${getText('every-n-weeks', {
          number: 4,
        })}`,
        value: WeeklyInterval.EveryFourWeeks,
      },
    ];
  }

  if (type == 'monthly') {
    return [
      { label: getText('every-month'), value: MonthlyInterval.EveryMonth },
      {
        label: `${getText('every-n-months', {
          number: 2,
        })}`,
        value: MonthlyInterval.EveryTwoMonths,
      },
      {
        label: `${getText('every-n-months', {
          number: 3,
        })}`,
        value: MonthlyInterval.EveryThreeMonths,
      },
      {
        label: `${getText('every-n-months', {
          number: 4,
        })}`,
        value: MonthlyInterval.EveryFourMonths,
      },
      {
        label: `${getText('every-n-months', {
          number: 5,
        })}`,
        value: MonthlyInterval.EveryFiveMonths,
      },
      {
        label: `${getText('every-n-months', {
          number: 6,
        })}`,
        value: MonthlyInterval.EverySixMonths,
      },
    ];
  }

  return [];
};

const getDayOptions = (): { value: string; label: string }[] => {
  const options = [{ value: 'None', label: getText('none') }];

  for (let i = 0; i < 31; i++) {
    options.push({
      value: '' + i,
      label: `${getText('day-capital')} ${i + 1}`,
    });
  }

  options.push({ value: '-1', label: getText('last-day') });

  return options;
};

export const ReoccurrenceField: FunctionComponent<
  PropsWithChildren<ReoccurrenceFieldProps>
> = ({ name, type, dueDateControlName }) => {
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error(getText('reoccurrence-must-have-context'));
  }
  const { formState, watch, getValues } = formContext;
  const error = formState.errors[name];
  const watchStartDate = watch(dueDateControlName);
  const watchEndDate = watch(`${name}.endDate`);

  // Custom validation to ensure start date is set before the end date
  const validateStartDate = (): boolean | string => {
    const formValues = getValues(),
      startDate = formValues[dueDateControlName],
      endDate = formValues[name]['endDate'];

    return !endDate || isDateBefore(startDate, endDate)
      ? true
      : getText('start-date-lower-end-date');
  };

  return (
    <View style={{ zIndex: zIndexAuto }}>
      {type == 'weekly' && (
        <>
          <Text style={styles.label}>{getText('days')}</Text>
          <Form.Row>
            <Form.Column>
              <CheckboxField name={`${name}.sunday`} label="Sun" />
            </Form.Column>
            <Form.Column>
              <CheckboxField name={`${name}.monday`} label="Mon" />
            </Form.Column>
            <Form.Column>
              <CheckboxField name={`${name}.tuesday`} label="Tue" />
            </Form.Column>
            <Form.Column>
              <CheckboxField name={`${name}.wednesday`} label="Wed" />
            </Form.Column>
            <Form.Column>
              <CheckboxField name={`${name}.thursday`} label="Thu" />
            </Form.Column>
            <Form.Column>
              <CheckboxField name={`${name}.friday`} label="Fri" />
            </Form.Column>
            <Form.Column>
              <CheckboxField name={`${name}.saturday`} label="Sat" />
            </Form.Column>
          </Form.Row>
        </>
      )}

      <Form.Row style={styles.frequencyContainer}>
        <Form.Column style={{ zIndex: zIndexAuto }}>
          <DateTimePickerField
            label={getText('time')}
            type="time"
            name={dueDateControlName}
          ></DateTimePickerField>
        </Form.Column>

        <>
          {type == 'monthly' && (
            <Form.Column>
              <DropdownSelect
                label={getText('day')}
                fieldName={`${name}.day`}
                options={getDayOptions()}
              />
            </Form.Column>
          )}
        </>

        <Form.Column>
          <View style={{ width: '100%' }}>
            <DropdownSelect
              label={getText('frequency')}
              fieldName={`${name}.frequency`}
              options={getIntervalOptions(type)}
            />
          </View>
        </Form.Column>
      </Form.Row>

      <Form.Row style={styles.dateContainer}>
        <Form.Column style={{ zIndex: zIndexAuto }}>
          <DateTimePickerField
            label={getText('start-date')}
            type="date"
            name={dueDateControlName}
            placeholder={getText('start-date')}
            rules={{
              required: getText('start-date-required'),
              validate: validateStartDate,
            }}
            minDate={new Date().toISOString()}
            maxDate={watchEndDate}
          ></DateTimePickerField>
        </Form.Column>
        <Form.Column style={{ zIndex: zIndexAuto }}>
          <DateTimePickerField
            label={getText('end-date')}
            type="date"
            name={`${name}.endDate`}
            minDate={watchStartDate || new Date().toISOString()}
            placeholder={getText('end-date')}
          ></DateTimePickerField>
        </Form.Column>
      </Form.Row>

      <Form.Row style={styles.showOnGridContainer}>
        <CheckboxField
          name={`${name}.showOnGrid`}
          label={getText('show-grid')}
        />
        <Text style={styles.showOnGridLabel} selectable>
          {getText('task-appear-grid')}
        </Text>
      </Form.Row>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  frequencyContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    zIndex: zIndexAuto,
  },
  showOnGridContainer: {
    // `Show on Grid` is not implemented on the back-end yet, once we need it, change to `display: "flex"`
    display: 'none',
    flexDirection: 'column',
    width: '100%',
  },
  showOnGridLabel: {
    marginTop: theme.getSpacing(1),
    marginLeft: theme.getSpacing(4),
    color: theme.palette.gray[500],
  },
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
  dateContainer: {
    marginBottom: 0,
    zIndex: zIndexAuto,
  },
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
    marginBottom: theme.getSpacing(1),
  },
}));

export enum WeeklyInterval {
  EveryWeek = 'every-week',
  EveryTwoWeeks = 'every-2-weeks',
  EveryThreeWeeks = 'every-3-weeks',
  EveryFourWeeks = 'every-4-weeks',
}

export enum MonthlyInterval {
  EveryMonth = 'every-month',
  EveryTwoMonths = 'every-2-months',
  EveryThreeMonths = 'every-3-months',
  EveryFourMonths = 'every-4-months',
  EveryFiveMonths = 'every-5-months',
  EverySixMonths = 'every-6-months',
}

export interface OccurrenceForm {
  sunday?: boolean;
  monday?: boolean;
  tuesday?: boolean;
  wednesday?: boolean;
  thursday?: boolean;
  friday?: boolean;
  saturday?: boolean;
  day?: string;
  endDate: string | null;
  frequency: WeeklyInterval | MonthlyInterval;
  showOnGrid?: boolean;
}

export interface ReoccurrenceFieldProps {
  name: string;
  dueDateControlName: string;
  type: 'weekly' | 'monthly';
}

export const ReoccurrenceFieldTestIDs = {
  error: 'dateTimePickerField-error-message',
};
