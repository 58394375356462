import { AxiosRequestConfig } from 'axios';
import {
  GeneralApi as UsersServiceClient,
  Ok,
  ResetPasswordRequestDto,
  ConfirmPasswordDto,
} from '@digitalpharmacist/users-service-client-axios';
import { BaseApiService } from 'assets/core/api';
import { USER_API_BASE_URL } from '../common/constants';

export interface IResetPasswordService {
  resetPassword(values: ResetPasswordRequestDto): Promise<Ok>;
  confirmPassword(values: ConfirmPasswordDto): Promise<Ok>;
}

export class ResetPasswordService
  extends BaseApiService
  implements IResetPasswordService
{
  private usersServiceClient: UsersServiceClient;
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = false,
  ) {
    super(baseUrl, config, enableAuth);
    this.usersServiceClient = new UsersServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async resetPassword(request: ResetPasswordRequestDto): Promise<Ok> {
    const { data } =
      await this.usersServiceClient.userPharmacistPasswordResetPost(request);
    return data;
  }

  async confirmPassword(request: ConfirmPasswordDto): Promise<Ok> {
    const { data } =
      await this.usersServiceClient.userPharmacistPasswordConfirmationPost(
        request,
      );
    return data;
  }
}

export default new ResetPasswordService(USER_API_BASE_URL);
