import { makeStyles, useTheme } from 'assets/theme';
import React from 'react';
import { View } from 'react-native';
import { DocumentIcon } from 'assets/icons';
import NewFormModal from '../new-form-modal/NewFormModal';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';

export default function NoForms() {
  const styles = useStyles();
  const theme = useTheme();
  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <View style={styles.iconContainer}>
          <DocumentIcon size={128} color={theme.palette.gray[300]} />
        </View>
        <Text style={styles.title} selectable>
          {getText('create-first-form')}
        </Text>
        <NewFormModal />
      </View>
    </View>
  );
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexDirection: 'column',
    height: '100%',
    margin: theme.getSpacing(4),
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.getSpacing(4),
  },
  title: {
    fontSize: 25,
    fontWeight: '700',
    paddingBottom: theme.getSpacing(4),
    paddingTop: theme.getSpacing(4),
  },
  iconContainer: {
    marginLeft: theme.getSpacing(3),
  },
}));
