import { getText } from 'assets/localization/localization';

type AdvancedDropDownFieldOption = { label: string; value: string };

export const PREFERRED_LANGUAGE_VALUES: AdvancedDropDownFieldOption[] = [
  { label: getText('english'), value: 'english' },
  { label: getText('spanish'), value: 'spanish' },
  { label: getText('chinese'), value: 'chinese' },
  { label: getText('french'), value: 'French' },
  { label: getText('vietnamese'), value: 'vietnamese' },
  { label: getText('arabic'), value: 'arabic' },
  { label: getText('korean'), value: 'korean' },
  { label: getText('russian'), value: 'Russian' },
  { label: getText('german'), value: 'German' },
];
