import React, { FunctionComponent, useEffect } from 'react';
import { View } from 'react-native';
import DatePicker from 'react-datepicker';
import shallow from 'zustand/shallow';
import moment from 'moment';

import { makeStyles } from 'assets/theme';
import { useForm } from 'assets/form';
import { Modal } from 'assets/components/modal';
import theme from 'assets/theme';
import { Form } from 'assets/layout/form/Form';
import { Text } from 'assets/components/text';

import { useUserState } from '../../store/user-store';
import { useTasksFiltersState } from './tasks-filters-store';
import { useTaskSettingsState } from '../../screens/settings/tasks/task-settings-store';
import { useTaskModalState } from '../task-modal/task-modal-store';
import {
  setAdvancedFilter,
  updateFiltersShowModalState,
} from './tasks-filters-actions';
import { AdvancedDropDownField } from '../../components/advanced-dropdown';
import { LoadingOverlay } from '../../components/LoadingOverlay';
import {
  UserOptionTemplate,
  UserSingleValueTemplate,
  TypeOptionTemplate,
  TypePlaceholderTemplate,
  TypeSingleValueTemplate,
  GenericOptionTemplate,
  GenericSingleValueTemplate,
  GenericPlaceholderTemplate,
  UserPlaceholderTemplate,
} from '../../components/advanced-dropdown/templates/advanced-drop-down-field.templates';

import './css/task-advanced-filters-date-picker.css';
import {
  DEFAULT_ADVANCED_FILTERS_DROPDOWN_DATA,
  getDefaultStaticDropdownData,
  initialAdvancedFiltersFormValue,
} from './task-filters.utils';
import { TaskVisibility } from '@digitalpharmacist/tasks-service-client-axios';
import { getText } from '../../../../../packages/assets/localization/localization';

export const TaskAdvancedFiltersModal: FunctionComponent<
  TaskTypeModalProps
> = () => {
  const styles = useStyles();
  const user = useUserState((state) => state?.data);

  const { taskTypes, settingsStatus } = useTaskSettingsState(
    (state) => ({
      taskTypes: state.taskTypes,
      settingsStatus: state.status,
    }),
    shallow,
  );

  const { showModal, filters } = useTasksFiltersState(
    (state) => ({
      showModal: state.showModal,
      filters: state.filters,
    }),
    shallow,
  );

  const { assigneeOptions, modalStatus } = useTaskModalState(
    (state) => ({
      assigneeOptions: state.assigneeOptions,
      modalStatus: state.status,
    }),
    shallow,
  );

  const isLoading = settingsStatus === 'loading' || modalStatus === 'loading';

  const methods = useForm({
    defaultValues: initialAdvancedFiltersFormValue,
  });

  const formValues = methods.watch();

  const defaultTaskType = useTaskSettingsState(
    (state) =>
      state.taskTypes.find((f) => f.id === filters?.task_type_id) ??
      initialAdvancedFiltersFormValue.task_type_id,
  );

  const defaultAssignedUser = useTaskModalState(
    (state) =>
      state.assigneeOptions.find(
        (f) =>
          f.id === filters?.assigned_user_id ||
          f.id === formValues?.assigned_user_id.id,
      ) ?? initialAdvancedFiltersFormValue.assigned_user_id,
  );

  const defaultCreatedByUser = useTaskModalState(
    (state) =>
      state.assigneeOptions.find(
        (f) =>
          f.id === filters?.created_by_user_id ||
          f.id === formValues?.created_by_user_id.id,
      ) ?? initialAdvancedFiltersFormValue.created_by_user_id,
  );

  useEffect(() => {
    const subscription = methods.watch((value) => {
      const { visibility, assigned_user_id, created_by_user_id } = value;
      const inputValues = Object.entries(value);
      const isPersonal = visibility?.id === TaskVisibility.Personal;

      for (const [key, value] of inputValues) {
        // If the input field is being cleared we are setting the default value for that field for clarity for the user
        if (value === null || value === undefined) {
          methods.setValue(key, initialAdvancedFiltersFormValue[key]);
          return;
        }
      }

      // If the visibility filter is set to personal we automatically fill in the assigned and created by inputs with the current user data
      // since currently a user has access only to personal tasks that he has created and is assignee
      if (
        isPersonal &&
        (assigned_user_id.id !== user?.id || created_by_user_id.id !== user?.id)
      ) {
        methods.reset({
          ...value,
          assigned_user_id: user,
          created_by_user_id: user,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [methods.watch]);

  useEffect(() => {
    const { status, priority, visibility } = getDefaultStaticDropdownData(
      filters as Record<string, string>,
    );

    if (!isLoading && showModal) {
      const taskTypeForm = {
        status,
        priority,
        visibility,
        task_type_id: defaultTaskType,
        assigned_user_id: defaultAssignedUser,
        created_by_user_id: defaultCreatedByUser,
        due_by: {
          startDate: filters?.min_due_date
            ? new Date(filters?.min_due_date)
            : null,
          endDate: filters?.max_due_date
            ? new Date(filters?.max_due_date)
            : null,
        },
      };

      methods.reset({
        ...taskTypeForm,
      });
    }
  }, [filters, isLoading, showModal]);

  const handleSubmit = () => {
    const formValue = methods.getValues();
    // Filtering out all of the untouched/default filters instead of setting incorrect data since the Task Service validation will throw 400 Bad request
    // in case of invalid parameter passed for example priority filter/field with value different than the TaskPriority enum
    const filteredValues = Object.entries(formValue)
      .filter(([, value]) => value?.id !== 'default')
      .reduce<Record<string, unknown>>((obj, [key, value]) => {
        obj[key] = value.id;

        return obj;
      }, {});

    const isReset =
      Object.keys(filteredValues).length === 1 &&
      formValue.due_by?.startDate === null &&
      formValue.due_by.endDate === null;

    setAdvancedFilter(
      {
        ...filteredValues,
        non_resolved_only: filteredValues?.status ? undefined : true,
        min_due_date: formValue?.due_by?.startDate
          ? moment
              .utc(moment(formValue?.due_by?.startDate))
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : undefined,
        max_due_date: formValue?.due_by?.endDate
          ? moment
              .utc(moment(formValue?.due_by?.endDate))
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : undefined,
        due_date: undefined,
      },
      !isReset,
    );
  };

  const handleReset = () => {
    methods.reset({ ...initialAdvancedFiltersFormValue });
  };

  const closeModal = () => {
    updateFiltersShowModalState(false);
    handleReset();
  };

  return (
    <Modal
      title={getText('task-filters')}
      titleSize="sm"
      dismissButtonProps={{
        onPress: closeModal,
        logger: { id: 'task-advanced-filters-form-dismiss-button-modal' },
      }}
      cancelButtonProps={{
        onPress: closeModal,
        logger: { id: 'task-advanced-filters-form-cancel-button-modal' },
      }}
      resetButtonProps={{
        onPress: handleReset,
        logger: { id: 'task-advanced-filters-form-reset-button-modal' },
      }}
      okButtonProps={{
        onPress: methods.handleSubmit(handleSubmit),
        logger: { id: 'task-advanced-filters-form-ok-button-modal' },
        hierarchy: 'pharmacy-primary',
        text: getText('ok'),
      }}
      show={showModal}
      contentContainerStyle={{
        marginTop: theme.getSpacing(0.5),
      }}
    >
      {isLoading && <LoadingOverlay />}
      {/* The div below exists only because it's needed to select the parent Modal element via CSS and update styles instead of
        extending the Modal core component with more properties.   */}
      <div id="modal-overflow">
        <Form methods={methods}>
          <Form.Row style={styles.fieldWrapper}>
            <Form.Column style={styles.labelWrapper}>
              <Text style={styles.label}>{getText('task-type')}</Text>
            </Form.Column>
            <Form.Column style={styles.inputWrapper}>
              <AdvancedDropDownField
                name="task_type_id"
                label=""
                options={[
                  initialAdvancedFiltersFormValue.task_type_id,
                  ...taskTypes,
                ]}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={TypeOptionTemplate}
                singleValueTemplate={TypeSingleValueTemplate}
                placeholderTemplate={TypePlaceholderTemplate}
                isMulti={false}
                isClearable={formValues?.task_type_id?.id !== 'default'}
                className="advanced-dropdown-horizontal-label"
                styles={{
                  container: (base) => ({ ...base }),
                }}
              />
            </Form.Column>
          </Form.Row>
          {/* Similar to the div above the class name "advanced-dropdown-horizontal-label" exist only so I can select the
            AdvancedDropdown component element and have the label and the input be lined up in a row instead of a column */}

          <Form.Row style={styles.fieldWrapper}>
            <Form.Column style={styles.labelWrapper}>
              <Text style={styles.label}>{getText('task-status')}</Text>
            </Form.Column>
            <Form.Column style={styles.inputWrapper}>
              <AdvancedDropDownField
                name="status"
                label=""
                options={DEFAULT_ADVANCED_FILTERS_DROPDOWN_DATA.status}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={GenericOptionTemplate}
                singleValueTemplate={GenericSingleValueTemplate}
                placeholderTemplate={GenericPlaceholderTemplate}
                isMulti={false}
                isClearable={formValues.status.id !== 'default'}
                isSearchable={false}
                className="advanced-dropdown-horizontal-label"
                styles={{
                  container: (base) => ({ ...base }),
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={styles.fieldWrapper}>
            <Form.Column style={styles.labelWrapper}>
              <Text style={styles.label}>{getText('task-assigned-to')}</Text>
            </Form.Column>
            <Form.Column style={styles.inputWrapper}>
              <AdvancedDropDownField
                name="assigned_user_id"
                label=""
                options={[
                  initialAdvancedFiltersFormValue.assigned_user_id,
                  ...assigneeOptions,
                ]}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) =>
                  `${optionValue.firstName} ${optionValue.lastName}`
                }
                optionTemplate={UserOptionTemplate}
                singleValueTemplate={UserSingleValueTemplate}
                placeholderTemplate={UserPlaceholderTemplate}
                isMulti={false}
                isDisabled={
                  formValues.visibility.id === TaskVisibility.Personal
                }
                isClearable={formValues.assigned_user_id.id !== 'default'}
                className="advanced-dropdown-horizontal-label"
                styles={{
                  container: (base) => ({ ...base }),
                }}
              />
            </Form.Column>
          </Form.Row>

          <Form.Row style={styles.fieldWrapper}>
            <Form.Column style={styles.labelWrapper}>
              <Text style={styles.label}>{getText('task-priority')}</Text>
            </Form.Column>
            <Form.Column style={styles.inputWrapper}>
              <AdvancedDropDownField
                name="priority"
                label=""
                options={DEFAULT_ADVANCED_FILTERS_DROPDOWN_DATA.priority}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue?.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={GenericOptionTemplate}
                singleValueTemplate={GenericSingleValueTemplate}
                placeholderTemplate={GenericPlaceholderTemplate}
                isMulti={false}
                isClearable={formValues.priority.id !== 'default'}
                isSearchable={false}
                className="advanced-dropdown-horizontal-label"
                styles={{
                  container: (base) => ({ ...base }),
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={styles.fieldWrapper}>
            <Form.Column style={styles.labelWrapper}>
              <Text style={styles.label}>{getText('task-created-by')}</Text>
            </Form.Column>
            <Form.Column style={styles.inputWrapper}>
              <AdvancedDropDownField
                name="created_by_user_id"
                label=""
                options={[
                  initialAdvancedFiltersFormValue.created_by_user_id,
                  ...assigneeOptions,
                ]}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue.id}
                getOptionLabel={(optionValue) =>
                  `${optionValue.firstName} ${optionValue.lastName}`
                }
                optionTemplate={UserOptionTemplate}
                singleValueTemplate={UserSingleValueTemplate}
                placeholderTemplate={UserPlaceholderTemplate}
                isMulti={false}
                isDisabled={
                  formValues.visibility.id === TaskVisibility.Personal
                }
                isClearable={formValues.created_by_user_id.id !== 'default'}
                className="advanced-dropdown-horizontal-label"
                styles={{
                  container: (base) => ({ ...base }),
                }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row style={styles.fieldWrapper}>
            <Form.Column style={styles.labelWrapper}>
              <Text style={styles.label}>{getText('task-due-by')}</Text>
            </Form.Column>
            <Form.Column style={styles.inputWrapper}>
              <View style={styles.datePickerWrapper} nativeID="due_by">
                <DatePicker
                  name="due_by"
                  selectsRange={true}
                  startDate={formValues.due_by.startDate}
                  endDate={formValues.due_by.endDate}
                  placeholderText="All dates"
                  onChange={([startDate, endDate]) => {
                    methods.setValue('due_by', { startDate, endDate });
                  }}
                  popperPlacement="top-start"
                  popperModifiers={[
                    {
                      name: 'offset',
                      options: {
                        offset: [5, -15],
                      },
                    },
                    {
                      name: 'preventOverflow',
                      options: {
                        rootBoundary: 'viewport',
                        tether: true,
                        altAxis: true,
                      },
                    },
                  ]}
                  isClearable={true}
                />
              </View>
            </Form.Column>
          </Form.Row>
          <Form.Row style={styles.fieldWrapper}>
            <Form.Column style={styles.labelWrapper}>
              <Text style={styles.label}>{getText('task-visibility')}</Text>
            </Form.Column>
            <Form.Column style={styles.inputWrapper}>
              <AdvancedDropDownField
                name="visibility"
                label=""
                options={DEFAULT_ADVANCED_FILTERS_DROPDOWN_DATA.visibility}
                menuPortalTarget={document.body}
                getOptionValue={(optionValue) => optionValue?.id}
                getOptionLabel={(optionValue) => optionValue.title}
                optionTemplate={GenericOptionTemplate}
                singleValueTemplate={GenericSingleValueTemplate}
                placeholderTemplate={GenericPlaceholderTemplate}
                isMulti={false}
                isClearable={formValues.visibility.id !== 'default'}
                isSearchable={false}
                className="advanced-dropdown-horizontal-label"
                styles={{
                  container: (base) => ({ ...base }),
                }}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </div>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  fieldWrapper: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  labelWrapper: {
    flex: 1,
  },
  inputWrapper: {
    flex: 3,
  },
  label: {
    color: theme.palette.black,
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 16,
  },
  datePickerWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

interface TaskTypeModalProps {}

export default TaskAdvancedFiltersModal;
