import {
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from '@ag-grid-community/core';
import { AxiosInstance } from 'axios';
import {
  DataGridPaginatedRemoteResponse,
  DataGridRemoteRequest,
  DataGridSearchablePaginatedRemoteRequest,
} from '../../types';
import { BaseRemoteDatasource } from './BaseRemoteDatasource';
import { GLOBAL_SEARCH_FIELD } from '../../constant';

export class SearchablePaginatedRemoteDatasource extends BaseRemoteDatasource {
  constructor(remoteUrl: string, axiosInstance?: AxiosInstance) {
    super(remoteUrl, axiosInstance);
  }

  transformDataGridRequest(requestParams: IServerSideGetRowsRequest) {
    const { pagination, sort, filter } = super.transformDataGridRequest(
      requestParams,
    ) as DataGridRemoteRequest;

    const paginatedRequest: DataGridSearchablePaginatedRemoteRequest = {
      limit: (pagination?.endRow ?? 0) - (pagination?.startRow ?? 0),
      offset: pagination?.startRow,
    };

    if (sort && sort.length)
      paginatedRequest.order_by = sort
        ?.map((x) => `${x.colId}:${x.sort}`)
        .join(',');
    console.log(filter);

    if (filter && filter[GLOBAL_SEARCH_FIELD])
      paginatedRequest.search = filter[GLOBAL_SEARCH_FIELD].filter!;

    return paginatedRequest;
  }

  handleResponse<T extends unknown>(
    response: T,
    params: IServerSideGetRowsParams,
  ): void {
    const data = response as DataGridPaginatedRemoteResponse;
    params.success({ rowData: data.results, rowCount: data.total });
  }
}
