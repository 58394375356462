import { CreateScheduleAvailabilityDto } from '@digitalpharmacist/appointment-service-client-axios';
import moment from 'moment';
import {
  DEFAULT_DATE_TIME_API_FORMAT,
  formatDate,
  formatDateTimeUtc,
} from '../../common/datetime-utils';
import {
  AvailabilityFormData,
  AvailabilityHours,
  AvailabilityOverride,
} from './AvailabilityForm';
import { getText } from 'assets/localization/localization';

const weekdayNames = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

type Weekday = {
  name: string;
  startTime: string;
  endTime: string;
};

export type AvailabilitiesData = Omit<
  AvailabilityFormData,
  'title' | 'isDefault'
>;

export const availabilityFields = [
  {
    label: getText('monday'),
    name: 'monday',
  },
  {
    label: getText('tuesday'),
    name: 'tuesday',
  },
  {
    label: getText('wednesday'),
    name: 'wednesday',
  },
  {
    label: getText('thursday'),
    name: 'thursday',
  },
  {
    label: getText('friday'),
    name: 'friday',
  },
  {
    label: getText('saturday'),
    name: 'saturday',
  },
  {
    label: getText('sunday'),
    name: 'sunday',
  },
];

export const formDataToAvailabilities = (
  data: AvailabilityFormData,
): CreateScheduleAvailabilityDto[] => {
  const weekdays: Weekday[] = [];
  const availabilities: CreateScheduleAvailabilityDto[] = [];

  weekdayNames.map((name) => {
    const value = data[name as keyof AvailabilityFormData] as AvailabilityHours;

    if (value) {
      weekdays.push({
        name: name,
        startTime: value.start,
        endTime: value.end,
      });
    }
  });

  weekdays.map((day) => {
    const existingAvailabilityIndex = availabilities.findIndex((availability) =>
      findAvailability(availability, day),
    );

    // Both Cal.com and the mobile application calendar work with Sunday being indexed at 0 as start of the week
    const isSunday = day.name.toLowerCase() === 'sunday';
    const weekDay = isSunday ? 0 : weekdayNames.indexOf(day.name) + 1;

    if (existingAvailabilityIndex < 0) {
      availabilities.push({
        startTime: formatDate(day.startTime, DEFAULT_DATE_TIME_API_FORMAT),
        endTime: formatDate(day.endTime, DEFAULT_DATE_TIME_API_FORMAT),
        days: [weekDay],
      });
    } else {
      availabilities[existingAvailabilityIndex].days.push(weekDay);
    }
  });

  const dateOverrides: CreateScheduleAvailabilityDto[] = [];

  data.availabilityOverrides.map((override) => {
    override.time.map((time) => {
      dateOverrides.push({
        date: moment(override.date).format(DEFAULT_DATE_TIME_API_FORMAT),
        startTime: formatDateTimeUtc(time!.start, DEFAULT_DATE_TIME_API_FORMAT),
        endTime: formatDateTimeUtc(time!.end, DEFAULT_DATE_TIME_API_FORMAT),
        days: [
          weekdayNames.indexOf(
            moment(override.date).format('dddd').toLowerCase(),
          ) + 1,
        ],
      });
    });
  });

  return [...availabilities, ...dateOverrides];
};

const findAvailability = (
  availability: CreateScheduleAvailabilityDto,
  day: Weekday,
) => {
  const startTime = moment(availability.startTime).format('HH:mm');
  const endTime = moment(availability.endTime).format('HH:mm');
  const dayStartTime = moment(day.startTime).format('HH:mm');
  const dayEndTime = moment(day.endTime).format('HH:mm');

  return startTime === dayStartTime && endTime === dayEndTime;
};

export const availabilitiesToFormData = (
  availabilities: CreateScheduleAvailabilityDto[],
): AvailabilitiesData => {
  const data: any = {};

  availabilities.map((availability) => {
    availability.days.map((day) => {
      // Displaying Sunday which is indexed at 0 a.k.a start of the week to be in last place
      const weekday =
        day === 0
          ? weekdayNames[weekdayNames.length - 1]
          : weekdayNames[day - 1];
      const startTime = formatDateTimeUtc(availability.startTime);
      const endTime = formatDateTimeUtc(availability.endTime);

      data[weekday as keyof AvailabilitiesData] = {
        start: startTime,
        end: endTime,
      };
      data[`condition-${weekday}`] = true;
      data[`input-${weekday}.start`] = startTime;
      data[`input-${weekday}.end`] = endTime;
    });
  });

  return data;
};

export const getAvailabilityOverridesFromAvailabilities = (
  availabilities: CreateScheduleAvailabilityDto[],
): AvailabilityOverride[] => {
  const overrides: AvailabilityOverride[] = [];

  availabilities.map((availability) => {
    if (availability.date) {
      const overrideIndex = overrides.findIndex(
        (item) => item.date === availability.date,
      );

      if (overrideIndex >= 0) {
        overrides[overrideIndex].time.push({
          start: availability.startTime,
          end: availability.endTime,
        });

        return;
      }

      overrides.push({
        date: availability.date,
        time: [{ start: availability.startTime, end: availability.endTime }],
      });
    }
  });

  return overrides;
};
