import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useRef,
} from 'react';
import { Platform } from 'react-native';
import { PlatformType } from '../types';

import { AgGridReact } from '@ag-grid-community/react'; // the AG Grid React Component
import {
  GridReadyEvent,
  GridApi,
  ColumnApi,
  GridSizeChangedEvent,
  PaginationChangedEvent,
} from '@ag-grid-community/core';
import { AllModules } from '@ag-grid-enterprise/all-modules';

import {
  DataGridToolbar,
  DataGridToolbarProps,
} from './data-grid-toolbar/DataGridToolbar';
import { DataGridOptions } from './types';
import { GLOBAL_SEARCH_FIELD } from './constant';
import './styles.css';

export const DataGrid: FunctionComponent<PropsWithChildren<DataGridProps>> = ({
  gridToolbarProps,
  gridOptions,
  platform = Platform.OS,
  isRichContent = false,
}) => {
  if (platform !== 'web')
    throw `${Platform.OS} not supported from [DataGrid] component. It support only web!`;

  const [gridApi, setGridApi] = useState<GridApi>();
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
  const [paginationTrigger, setPaginationTrigger] = useState(false);
  const gridApiRef = useRef(gridApi);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
    setGridColumnApi(event.columnApi);
    event.api.sizeColumnsToFit();
    gridApiRef.current = event.api;
    gridOptions.onGridReady && gridOptions.onGridReady(event);
  };

  const onGridSizeChanged = (event: GridSizeChangedEvent) => {
    gridApiRef.current?.sizeColumnsToFit();
  };

  const onPaginationChanged = (event: PaginationChangedEvent) => {
    setPaginationTrigger(!paginationTrigger);
    gridOptions.onPaginationChanged && gridOptions.onPaginationChanged(event);
  };

  return (
    <DataGridToolbar
      {...gridToolbarProps}
      gridApi={gridApi}
      gridColumnApi={gridColumnApi}
      paginationTrigger={paginationTrigger}
    >
      <div
        className="ag-theme-material"
        style={{ width: '100%', height: '100%' }}
      >
        <AgGridReact
          {...gridOptions}
          columnDefs={[
            // adding a custom search field to handle the global search filtering
            // grid server side data source will discard columns that are not configured on the grid columnDefs
            // suppressed and hided from every grid context for the ui part
            {
              field: GLOBAL_SEARCH_FIELD,
              sortable: false,
              filter: 'agTextColumnFilter',
              suppressMenu: true,
              hide: true,
              suppressAutoSize: true,
              suppressColumnsToolPanel: true,
              suppressFiltersToolPanel: true,
            },
            ...(gridOptions.columnDefs ?? []),
          ]}
          onGridReady={onGridReady}
          rowModelType={gridOptions.rowModelType ?? 'clientSide'}
          modules={AllModules}
          suppressPaginationPanel={true}
          onPaginationChanged={onPaginationChanged}
          rowClass={isRichContent ? 'rich-content' : ''}
          colResizeDefault="shift"
          icons={{
            sortAscending: '<i class="ag-icon ag-icon-small-down"/>',
            sortDescending: '<i class="ag-icon ag-icon-small-up"/>',
          }}
          onGridSizeChanged={onGridSizeChanged}
        />
      </div>
    </DataGridToolbar>
  );
};

export interface DataGridProps {
  gridToolbarProps?: DataGridToolbarProps;
  gridOptions: DataGridOptions;
  platform?: PlatformType;
  isRichContent?: boolean;
}

export const DataGridTestIDs = {
  name: 'DataGridTestIDs-name',
};
