import {
  DefaultApi as PMSIntegrationServiceClient,
  PmsConfigDto,
  PmsDto,
} from '@digitalpharmacist/pms-integration-service-client-axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { AxiosRequestConfig } from 'axios';
import { PMS_INTEGRATIONS_API_BASE_URL } from '../common/constants';

export interface IPMSIntegrationsService {
  createPMSConfig(
    pharmacyId: string,
    locationId: string,
    data: PmsConfigDto,
    overwriteDefaults: boolean,
  ): unknown;
  findPMSConfig(locationId: string): unknown;
  updatePMSConfig(
    pharmacyId: string,
    locationId: string,
    data: PmsConfigDto,
  ): unknown;
  pmsFindAll(): Promise<PmsDto[]>;
}

export class PMSIntegrationsService
  extends BaseApiService
  implements IPMSIntegrationsService
{
  private pmsIntegrationServiceClient: PMSIntegrationServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.pmsIntegrationServiceClient = new PMSIntegrationServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async createPMSConfig(
    pharmacyId: string,
    locationId: string,
    data: PmsConfigDto,
    overwriteDefaults: boolean,
  ) {
    const response = await this.pmsIntegrationServiceClient.pmsConfigsCreate(
      pharmacyId,
      locationId,
      data,
      {
        params: {
          overwrite: overwriteDefaults ? 1 : 0,
        },
      },
    );
    return response;
  }

  async findPMSConfig(locationId: string) {
    const response =
      await this.pmsIntegrationServiceClient.pmsConfigsFindOne(locationId);
    return response;
  }

  async updatePMSConfig(
    pharmacyId: string,
    locationId: string,
    data: PmsConfigDto,
  ) {
    const response = await this.pmsIntegrationServiceClient.pmsConfigsUpdate(
      pharmacyId,
      locationId,
      data,
    );
    return response;
  }

  async pmsFindAll(): Promise<PmsDto[]> {
    const { data } = await this.pmsIntegrationServiceClient.pmsFindAll();
    return data;
  }
}

export default new PMSIntegrationsService(
  PMS_INTEGRATIONS_API_BASE_URL,
  undefined,
  true,
);
