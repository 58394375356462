import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { View } from 'react-native';
import {
  useFocusEffect,
  useNavigation,
  useRoute,
  useIsFocused,
} from '@react-navigation/native';
import { useWatch } from 'react-hook-form';
import { OptionProps } from 'react-select';
import { useForm } from 'assets/form';
import { Form } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { AppointmentSlotPicker } from '../appointment-slot-picker/AppointmentSlotPicker';
import { parseTimeSlots } from './appointment-form-utils';
import { getServices } from '../services-list/services-list-actions';
import { useServicesListState } from '../services-list/services-list-store';
import { AdvancedDropDownField } from '../../components/advanced-dropdown';
import { Icon } from 'assets/components/icon';
import { CheckIcon } from 'assets/icons';
import { Text } from 'assets/components/text';
import usePatientStore from '../../screens/patients/patient-store';
import patientServiceInstance from '../../api/PatientService';
import { useAppStateStore } from '../../store/app-store';
import { LocationPatientRecordDtoWithFullName } from '../../screens/patients/patient-types';
import { formatDate, formatDateTimeApi } from '../../common/datetime-utils';
import {
  createBooking,
  getAppointmentSlots,
  getBooking,
  getPatientRecord,
  setBooking,
  setIsDisabled,
  setLocationPatientRecord,
  setPatientRecord,
  setSlots,
  setSubmit,
  updateBooking,
} from './appointment-form-actions';
import {
  AppointmentTypeDto,
  CreateBookingDto,
  UpdateBookingDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import moment from 'moment';
import { useAppointmentFormState } from './appointment-form-store';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { ScheduleDrawerNavigationProp } from '../../layout/ScheduleDrawer';
import { Tooltip } from '../../components/Tooltip';
import { TooltipWrapper } from 'react-tooltip';
import { getText } from '../../../../../packages/assets/localization/localization';
import { TextField } from '../../../../../packages/assets/components/text-field';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';

type DropdownOption = {
  id: string;
  title?: string;
  full_name?: string;
  date_of_birth?: string;
  patient_record_id?: string | null;
};

interface MockAppointmentLocationDto {
  id: string;
  title: string;
  isDisabled?: boolean;
}

const mockLocationValues = [
  {
    title: getText('venue-in-person'),
    id: 'in-person',
  },
  {
    title: getText('venue-virtual'),
    id: 'virtual',
    isDisabled: true,
  },
  {
    title: getText('venue-over-the-phone'),
    id: 'over-the-phone',
    isDisabled: true,
  },
];

export const PatientOptionTemplate = (props: OptionProps<DropdownOption>) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSelected =
    props.selectProps.value &&
    (props.selectProps.value as DropdownOption).id === props.data.id;

  const PatientRecordOptionRender = (props: {
    isDisabled: boolean;
    label: string;
    id: string;
  }) => (
    <>
      <TooltipWrapper tooltipId={props.id}>
        <View style={styles.dropdownOptionContainer}>
          <Text
            style={[
              styles.dropdownOptionLabel,
              props.isDisabled ? styles.disabledOptionText : null,
            ]}
          >
            {props.label}
          </Text>
          {isSelected && (
            <Icon
              icon={CheckIcon}
              size={20}
              color={theme.palette.primary[600]}
            />
          )}
        </View>
      </TooltipWrapper>
    </>
  );

  return props.data.patient_record_id ? (
    <PatientRecordOptionRender
      isDisabled={false}
      label={props.label}
      id={props.data.id}
    />
  ) : (
    <>
      <TooltipWrapper tooltipId={props.data.id}>
        <PatientRecordOptionRender
          isDisabled={true}
          label={props.label}
          id={props.data.id}
        />
      </TooltipWrapper>
      <Tooltip
        text={getText('missing-important-patient-record-data')}
        id={props.data.id}
      />
    </>
  );
};

const DropdownOptionTemplate = (
  props: OptionProps<DropdownOption | MockAppointmentLocationDto>,
) => {
  const styles = useStyles();
  const theme = useTheme();
  const isSelected =
    props.selectProps.value &&
    (props.selectProps.value as DropdownOption).id === props.data.id;

  return (
    <View style={styles.dropdownOptionContainer}>
      <Text
        style={[
          styles.dropdownOptionLabel,
          props.isDisabled ? styles.disabledOptionText : null,
        ]}
      >
        {props.label}
      </Text>
      {isSelected && (
        <Icon icon={CheckIcon} size={20} color={theme.palette.primary[600]} />
      )}
    </View>
  );
};

const defaultFormValues = {
  service: undefined,
  patient: undefined,
  time: '',
  notes: '',
  location: mockLocationValues[0],
};

export const AppointmentForm: FunctionComponent<AppointmentFormProps> = (
  props,
) => {
  const methods = useForm<AppointmentFormData>({
    defaultValues: defaultFormValues,
  });
  const styles = useStyles();
  const theme = useTheme();
  const { services, status: servicesStatus } = useServicesListState();
  const { patients, setPatients } = usePatientStore();
  const [editPatient, setEditPatient] = useState<PatientRecordDto[]>();
  const { locationId } = useAppStateStore();
  const {
    slots,
    slotsStatus,
    submit,
    booking,
    locationPatientRecord,
    patientRecord,
    slotsAppointmentTypeId,
  } = useAppointmentFormState();
  const navigation = useNavigation<ScheduleDrawerNavigationProp>();
  const route = useRoute<any>();
  const isFocused = useIsFocused();
  const appointmentId = route.params?.appointmentId;
  const isPrefilled = route.params?.isPrefilled;
  const edit = !!appointmentId && isFocused;
  const selectedService: AppointmentTypeDto = useWatch({
    name: 'service',
    control: methods.control,
  });

  useFocusEffect(
    useCallback(() => {
      setIsDisabled(true);
      getServices({ withoutNextAvailableSlot: true });

      if (edit) {
        getBooking(appointmentId);
      } else {
        if (!isPrefilled) {
          getPatients();
        }
      }

      return () => {
        methods.reset(defaultFormValues);
        setSlots([]);
        setLocationPatientRecord(undefined);
        setPatientRecord(undefined);
        setBooking(undefined);
        methods.setValue('patient', null);
      };
    }, [appointmentId]),
  );

  useFocusEffect(
    useCallback(() => {
      if (booking?.patient_record_id) {
        getPatientRecord(booking.patient_record_id);
      }

      if (
        patientRecord &&
        (patientRecord.id === booking?.patient_record_id || isPrefilled)
      ) {
        const localPatientRecord = {
          ...patientRecord,
          full_name: `${patientRecord.first_name} ${patientRecord.last_name}`,
        };

        methods.setValue('patient', localPatientRecord);
        setEditPatient([localPatientRecord]);
      } else {
        methods.setValue('patient', null);
      }

      if (!edit) return;

      const service = services?.find(
        (item) => item.id === booking?.appointment_type_id,
      );

      if (service) {
        methods.setValue('service', service);
      }

      if (booking?.pharmacy_notes) {
        methods.setValue('notes', booking.pharmacy_notes);
      }

      if (booking?.startTime) {
        methods.setValue('time', booking.startTime);
      }
    }, [booking, services, patientRecord?.id, isPrefilled]),
  );

  useEffect(() => {
    if (selectedService) {
      const startDate = moment().startOf('month');

      getAppointmentSlots(
        selectedService.id,
        formatDateTimeApi(startDate),
        formatDateTimeApi(startDate.add(4, 'month')),
      );
    }
  }, [selectedService]);

  useEffect(() => {
    if (submit) {
      methods.handleSubmit(handleSubmit)();
      setSubmit(false);
    }
  }, [submit]);

  const getPatients = async () => {
    const data =
      await patientServiceInstance.findAllLocationsRecordsById(locationId);

    setPatients(
      data.map(
        (x) =>
          ({
            ...x,
            full_name: `${x.first_name} ${x.last_name}`,
          }) as LocationPatientRecordDtoWithFullName,
      ),
    );
  };

  const handleSubmit = async () => {
    const data = methods.getValues();
    const startTime = data.time;
    const endTime = formatDateTimeApi(
      moment(data.time).add(data.service.length, 'minutes'),
    );

    if (edit && booking) {
      const updateBookingDto: UpdateBookingDto = {
        ...booking,
        startTime: startTime,
        endTime: endTime,
        pharmacy_notes: data.notes,
      };

      await updateBooking(booking.id, updateBookingDto);
      navigation.navigate('appointments');
    } else {
      // TODO: Type casting should not be required here. The below code needs to be refactored or the AdvancedDropdown interface
      if (
        (data.patient as LocationPatientRecordDtoWithFullName).patient_record_id
      ) {
        const bookingDto: CreateBookingDto = {
          appointment_type_id: data.service.id,
          // user_id has type object coming from the API although it is as string see 'LocationPatientRecordDto, which is the reason for the type casting below
          patient_user_id: data.patient?.user_id
            ? (data.patient.user_id as unknown as string)
            : undefined,
          // TODO: Type casting should not be required here. The below code needs to be refactored or the AdvancedDropdown interface
          patient_record_id: (
            data.patient as LocationPatientRecordDtoWithFullName
          ).patient_record_id as string,
          title: data.service.title,
          description: data.service.description,
          startTime: startTime,
          endTime: endTime,
          timeZone: 'US/Central',
          pharmacy_notes: data.notes ? data.notes : undefined,
          submissions: [],
        };

        await createBooking(bookingDto);
        navigation.navigate('appointments');
      }
    }
  };

  const getPatientLabel = (option: DropdownOption) => {
    return `${option.full_name} (${formatDate(option.date_of_birth!)})`;
  };

  const handleSlotChange = (time: string) => {
    if (time) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  return (
    <>
      {servicesStatus === 'loading' ||
      (!editPatient?.length && !patients.length) ? (
        <LoadingIndicator color={theme.colors.pharmacyPrimary} />
      ) : (
        <View style={styles.container}>
          <Form methods={methods}>
            <Form.Row style={styles.formRow}>
              <Form.Column>
                <AdvancedDropDownField
                  name="service"
                  label={`${getText('service')} *`}
                  isMulti={false}
                  options={services}
                  isClearable={false}
                  menuPortalTarget={document.body}
                  getOptionValue={(optionValue) => optionValue.id}
                  getOptionLabel={(optionValue) =>
                    optionValue.title ? optionValue.title : ''
                  }
                  optionTemplate={DropdownOptionTemplate}
                  rules={{ required: getText('service-required') }}
                  isDisabled={edit}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.formRow}>
              <Form.Column>
                <AdvancedDropDownField
                  name="patient"
                  label={`${getText('patient')} *`}
                  isMulti={false}
                  options={patients}
                  isClearable={false}
                  menuPortalTarget={document.body}
                  getOptionValue={(optionValue) => optionValue.id}
                  getOptionLabel={getPatientLabel}
                  optionTemplate={PatientOptionTemplate}
                  rules={
                    !edit
                      ? { required: getText('patient-is-required') }
                      : undefined
                  }
                  isDisabled={edit || !!locationPatientRecord || !!isPrefilled}
                  isOptionDisabled={(option) => !option.patient_record_id}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.formRow}>
              <Form.Column>
                <AdvancedDropDownField
                  name="location"
                  label={getText('venue')}
                  isMulti={false}
                  options={mockLocationValues}
                  isClearable={false}
                  menuPortalTarget={document.body}
                  getOptionValue={(optionValue) => optionValue.id}
                  getOptionLabel={(optionValue) =>
                    (optionValue as MockAppointmentLocationDto).title
                  }
                  optionTemplate={DropdownOptionTemplate}
                  rules={{ required: getText('field-required') }}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row style={styles.formRow}>
              <Form.Column>
                <TextField
                  placeholder={getText('notes')}
                  name="notes"
                  type="text"
                  multiline={true}
                  numberOfLines={3}
                />
              </Form.Column>
            </Form.Row>
            <View style={styles.slotPickerContainer}>
              {slotsStatus === 'loading' && selectedService ? (
                <LoadingIndicator color={theme.colors.pharmacyPrimary} />
              ) : null}
              {slots.length &&
              slotsStatus !== 'loading' &&
              selectedService &&
              slotsAppointmentTypeId === selectedService.id ? (
                <AppointmentSlotPicker
                  slots={parseTimeSlots(
                    edit && booking
                      ? [
                          ...slots,
                          {
                            time: booking.startTime,
                          },
                        ]
                      : slots,
                  )}
                  name="time"
                  onSlotChange={handleSlotChange}
                />
              ) : null}
            </View>
          </Form>
        </View>
      )}
    </>
  );
};

export interface AppointmentFormProps {}

interface AppointmentFormData {
  service: AppointmentTypeDto;
  patient: LocationPatientRecordDtoWithFullName | PatientRecordDto | null;
  time: string;
  notes: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
  },
  formRow: {
    maxWidth: 400,
  },
  dropdownOptionContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: theme.getSpacing(0.5),
    margin: 0,
  },
  disabledOptionText: {
    color: theme.palette.gray[400],
  },
  dropdownOptionLabel: {
    ...theme.fonts.regular,
    fontSize: 16,
    lineHeight: 18,
    marginRight: 'auto',
  },
  slotPickerContainer: {
    marginTop: theme.getSpacing(3),
    paddingTop: theme.getSpacing(3),
    borderTopColor: theme.palette.gray[300],
    borderTopWidth: 1,
  },
}));
